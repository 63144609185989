import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useDispatch } from 'react-redux';
import { setWidgetPdf } from '../../store';
import "./index.scss";

const PDFViewer = ({file}) => {
    const reduxDispatch = useDispatch();
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

	const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

    const disableNavCheck = numPages===1 || pageNumber===numPages;

	return (
		<div className='pdf-viewer-wrap'>
			<nav>
				<button type='button' disabled={disableNavCheck} className='btn btn-primary' onClick={goToPrevPage}>Prev</button>
				<button type='button' disabled={disableNavCheck} className='btn btn-primary' onClick={goToNextPage}>Next</button>
				<div className='page-details'>
					Page {pageNumber} of {numPages}
				</div>
                <button type='button' className='btn btn-danger btn-cell close-pdf-viewer' onClick={()=>reduxDispatch(setWidgetPdf(null))}>
                    <i className='icon-close' />
                </button>
			</nav>
			<Document className='pdf-loader' file={file} onLoadSuccess={onDocumentLoadSuccess}>
				<Page pageNumber={pageNumber} />
			</Document>
		</div>
	);
};

export default PDFViewer;

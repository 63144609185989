import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Calendar } from '../';
import { setDt, setView, setFilters, toggleDashboardNav } from '../../store';
import { Tooltip } from '../../ui-components';
import "./index.scss";
import FilterGroup from './filter-group';

const ComponentNav = () => {
    const reduxDispatch = useDispatch();
    const { dt, view, navOpen } = useSelector(state => state?.dashboard);
    const { eventTypes, filters, companies } = useSelector(state => state?.app);

    const setFiltersFn = (category, value) => {
        const categoryFilter = filters[category];
        const newCategoryFilter = categoryFilter.indexOf(value)>-1 ? categoryFilter.filter(i=>i!==value) : [...categoryFilter, value];
        reduxDispatch(setFilters({...filters, [category]: newCategoryFilter}));
    }

    const selectAllFilters = (category, list) => {
        reduxDispatch(setFilters({...filters, [category]: list}));
    }

    const clearEventFilters = () => {
        if(filters['events']?.length) {
            reduxDispatch(setFilters({...filters, events: []}));
        }
    }

    const clearCompanyFilters = () => {
        if(filters['companies']?.length) {
            reduxDispatch(setFilters({...filters, companies: []}));
        }
    }

    return <>
        <div className={`app-nav ${navOpen?'open':''}`}>
            <div className='nav-container'>
                <Calendar className='nav-calendar' type="date" display="inline" value={dt} onSelect={e=>reduxDispatch(setDt(e))} />
                <div className='calendar-view'>
                    <div className="text-label">Change calendar type</div>
                    <div className='calendar-view-container'>
                        <Tooltip title="Day" disableChild={false}>
                            <button type='button' onClick={e=>reduxDispatch(setView('schedule'))} className={`btn btn-${view==='schedule'?'primary':'secondary'} btn-cell calendar-view-schedule`}>
                                <i className='icon-schedule font-size-14' />
                            </button>
                        </Tooltip>
                        <Tooltip title="Week" disableChild={false}>
                            <button type='button' onClick={e=>reduxDispatch(setView('week'))} className={`btn btn-${view==='week'?'primary':'secondary'} btn-cell calendar-view-week`}>
                                <i className='icon-week-columns font-size-14' />
                            </button>
                        </Tooltip>
                        <Tooltip title="Month" disableChild={false}>
                            <button type='button' onClick={e=>reduxDispatch(setView('month'))} className={`btn btn-${view==='month'?'primary':'secondary'} btn-cell calendar-view-month`}>
                                <i className='icon-calendar font-size-14' />
                            </button>
                        </Tooltip>
                    </div>
                </div>

                <FilterGroup title='Type Filter' filters={filters['events']} list={eventTypes.map(item=>({...item,label:item?.label,value:item?.label}))} onClearFilters={clearEventFilters} onChange={value=>setFiltersFn('events', value)} onSelectAllFilters={()=>selectAllFilters('events', eventTypes.map(item=>item?.label))} />

                <FilterGroup title='Company Filter' filters={filters['companies']} list={companies.map(item=>({...item,label:item?.name,value:item?.tag}))} onClearFilters={clearCompanyFilters} onChange={value=>setFiltersFn('companies', value)} onSelectAllFilters={()=>selectAllFilters('companies', companies.map(item=>item?.tag))} />
            </div>
        </div>
        <div className='app-nav-bg' onClick={()=>reduxDispatch(toggleDashboardNav())} />
    </>;
};

export default ComponentNav;
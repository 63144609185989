import { apiUrl } from "../constants";

export const guid = () => Date.now().toString(36) + Math.random().toString(36).substr(2);
export const prePad = (i='', n=2, z='0') => i.toString().padStart(n, z);

export const mockGuids = () => {
    const result = [];
    for(let i=0; i<4; i++) {
        result.push(guid());
    }
    return result;
}

const initialMinutes = (new Date().getHours()*60) + new Date().getMinutes();
export const getTimeFromMinutes = (minutes=initialMinutes) => {
    const hours = Math.floor(minutes/60);
    const remains = minutes%60;
    const time = hours%12 === 0 ? 12 : hours%12;
    const ampm = hours > 11 ? 'pm' : 'am';
    return {
        string: `${prePad(time)}:${prePad(remains)} ${ampm}`,
        hours24: hours,
        hours: time,
        minutes: remains,
        ampm,
        value: minutes
    };
}

export const renderUploadType = (path='events/', upload, w=50, h=50, onImageLoad=()=>{}) => {
    const acceptableImageExtensions = ['jpg', 'jpeg', 'png', 'bmp', 'gif'];
    const ext = upload.split('.').pop();
    if(acceptableImageExtensions.includes(ext.toLowerCase())) {
        return <img className='upload-file image' onLoad={()=>onImageLoad(upload)} src={`${apiUrl}/image?path=${path}${upload}&w=${w}&h=${h}`} alt="UPLOAD" />;
    } else if(ext.toLowerCase() === 'svg') {
        return <img className='upload-file svg' onLoad={()=>onImageLoad(upload)} src={`uploads/${path}${upload}`} alt="UPLOAD" />;
    }
    return <div className='upload-file doc'>{ext}</div>;
}

export const renderInterviewStatus = e => {
    const { type, interviewStatus } = e;
    if(type === 'INTERVIEW') {
        switch(interviewStatus) {
            case 2:
                return <i className='icon-tick' />
            case 3:
                return <i className='icon-close' />
            default:
                return <i className='icon-chevron-right' />
        }
    }
    return '';
}

export const buildAcronym = (str='', n=2) => {
    const strArr = str.split(' ');
    const buildCharacter = (string, l=1) => {
        const word = string.replace(/[^A-Za-z]+/g, '');
        for(let i=0; i<l; i++) {
            res += word[i].toUpperCase();
        }
    }
    let res = '';
    if(strArr.length >= n) {
        for(let i=0; i<n; i++) {
            buildCharacter(strArr[i]);
        }
    } else {
        buildCharacter(strArr[0], 2);
    }
    return res;
 };

export const getDuration = (sd='Mon Mar 01 2010',ed=new Date().getTime()) => {
    const startDt = new Date(sd);
    const startDate = startDt.getDate();
    const startMonth = startDt.getMonth();
    const startYear = startDt.getFullYear();
    const currentDt = new Date(ed);
    const currentDate = currentDt.getDate();
    let currentMonth = currentDt.getMonth();
    let currentYear = currentDt.getFullYear();

    let days = 0;
    let months = 0;

    if (currentDate < startDate) {
        days = (currentDate - startDate + 30);
        currentMonth = currentMonth - 1;
    } else {
        days = (currentDate - startDate);
    }

    if (currentMonth < startMonth) {
        months = (currentMonth - startMonth + 12);
        currentYear = currentYear - 1;
    } else {
        months = (currentMonth - startMonth);
    }

    const years = (currentYear - startYear);

    const yearsText = years ? `${years} year${years!==1?'s':''}` : null;
    const monthsText = months ? `${months} month${months!==1?'s':''}` : null;
    const daysText = days ? `${days} day${days!==1?'s':''}` : null;
    const result = [yearsText, monthsText, daysText];

    return { years, months, days, text: result.filter(Boolean).join(', ')};
}

export const nl2br = text => {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
}

export const urlify = text => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, url => `<a href="${url}" target="_blank">${url}</a>`);
}

export const getWeddingAnniversaryShort = ({date, selectedDate}) => {
    const selectedDt = new Date(selectedDate);
    const newDate = new Date(date);
    if(selectedDt.getFullYear() === newDate.getFullYear()) {
        return { long: 'WEDDING DAY', short: 'WD' };
    }
    return { long: 'WEDDING ANNIVERSARY', short: 'WA' };
}

export const fixedDepositCalculation = (amount=0, interestRate=7, tenure=2) => {
    let maturityAmount = Number(amount);
    let data = [];
    for(let i=0; i<tenure; i++) {
        const interestAmount = (maturityAmount/100) * interestRate;
        maturityAmount += Number(interestAmount);
        data[i] = {'Interest Amount': interestAmount, Total: maturityAmount};
    }
    return data;
}

export const recurringDepositCalculation = (emi=0, frequency=12, interestRate=7, tenure=0) => {
    let maturityAmount = 0;
    let data = [];
    for(let i=0; i<(tenure*frequency); i++) {
        maturityAmount += Number(emi);
        const interestAmount = ((maturityAmount/100) * interestRate) / frequency;
        const accumulatedAmount = emi * (i+1);
        maturityAmount += Number(interestAmount);
        data[i] = {'Accumulated Amount': accumulatedAmount, 'Interest Amount': interestAmount, Total: maturityAmount};
    }
    return data;
}

export const loanInterestCalculation = (loanAmount=0, emi=0, interestRate=0, interestRateChange=0) => {
    let data = [];
    if(loanAmount && emi && interestRate && interestRateChange>0) {
        let startingBalance = Number(loanAmount);
        let closingBalance = 0;
        for(let i=0; (startingBalance>0 && i<30); i++) {
            const yearlyEMI = Number(emi*12);
            closingBalance = startingBalance - yearlyEMI;
            const newInterestRate = Number(interestRate+(i*interestRateChange));
            const interestAmount = ((startingBalance/100) * newInterestRate);
            data[i] = {'Year': i+1, 'Starting Balance': Number(startingBalance), 'Interest Rate': newInterestRate, 'Interest Amount': interestAmount, 'Closing Balance': Number(closingBalance + interestAmount), 'Monthly EMI': Number(emi), 'Yearly EMI': yearlyEMI};
            startingBalance = Number(closingBalance + interestAmount);
        }
    }
    return data;
}


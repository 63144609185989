import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchHandler } from '../fetchHandler';

export const fetchTransactions = createAsyncThunk('fetchTransactions', async ({ bank, start, end }) => {
    const response = await fetchHandler({
        url: `/transactions/${bank}/${start.toDateString()}/${end.toDateString()}`,
        method: 'GET'
    });
    return response?.data;
});

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        transactions: {}
    },
    extraReducers: {
        [fetchTransactions.pending]: (state) => {
            state.transactions = {}
        },
        [fetchTransactions.fulfilled]: (state, action) => {
            state.transactions = action.payload?.data;
        }
    }
});

export default transactionsSlice.reducer
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchHandler } from "../fetchHandler";

export const fetchExcels = createAsyncThunk("fetchExcels", async () => {
    const response = await fetchHandler({
        url: `/excels/get-excels`,
        method: "GET"
    });
    return response;
});

export const createUpdateExcel = createAsyncThunk("createUpdateExcel", async (body) => {
        const response = await fetchHandler({
            url: `/excels/add-edit`,
            method: "POST",
            body
        });
        return response;
    }
);

export const deleteExcel = createAsyncThunk("deleteExcel", async (guid) => {
    const response = await fetchHandler({
        url: `/excels/remove`,
        method: "DELETE",
        body: { guid }
    });
    return response;
});

export const excelsSlice = createSlice({
    name: "excels",
    initialState: {
        fetching: false,
        excelsData: {},
    },
    extraReducers: {
        [fetchExcels.pending]: (state) => {
            state.fetching = true;
            state.excelsData = {};
        },
        [fetchExcels.fulfilled]: (state, action) => {
            state.fetching = false;
            state.excelsData = action?.payload?.data?.result;
        },
        [deleteExcel.fulfilled]: (state, action) => {
            const {
                payload: { status },
                meta: { arg },
            } = action;
            if (status === 200) {
                state.excelsData = {
                    ...state?.excelsData,
                    result: state?.excelsData?.result?.filter(
                        (i) => i?.guid !== arg
                    ),
                };
            }
        },
    },
});

export default excelsSlice.reducer;

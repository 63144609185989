import React, { createContext, useContext, useEffect, useState } from 'react';
import { generateGuid } from '../../utils';
import "./index.scss";

export const AlertContext = createContext();

const Alert = () => {
    const { alerts, closeNotify } = useContext(AlertContext);

    useEffect(() => {
        let interval = setInterval(() => {
            const time = new Date().getTime() - 10000;
            const alertsToClose = alerts.filter(alert => alert.created < time);
            alertsToClose?.forEach(al => closeNotify(al));
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [alerts, closeNotify]);

    const onCloseAlert = alert => closeNotify(alert);

    const renderAlert = () => {
        return alerts.map(alert => <div key={alert?.guid} className={`alert ${alert?.type} ${alert?.className ? alert?.className : ''}`}>
            <div className='alert-message'>{alert?.message}</div>
            <div className='alert-close' onClick={()=>onCloseAlert(alert)}><i className='icon-close font-size-10' /></div>
        </div>)
    }

    return <div className='alert-wrap'>
        {renderAlert()}
    </div>
}

const AlertProvider = ({children}) => {
    const [alerts, setAlerts] = useState([]);

    const notify = alert => {
        setAlerts([{guid: generateGuid(), created: new Date().getTime(), ...alert},...alerts]);
    }

    const closeNotify = alert => {
        setAlerts(alerts?.filter(item => item?.guid !== alert?.guid));
    }

    return <AlertContext.Provider value={{ alerts, notify, closeNotify }}>
        <Alert />
        {children}
    </AlertContext.Provider>;
}

export default AlertProvider;

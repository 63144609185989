import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchHandler } from '../fetchHandler';

export const fetchCalculations = createAsyncThunk('fetchCalculations', async () => {
    const response = await fetchHandler({
        url: '/financial-calculations/get-list',
        method: 'GET'
    });
    return response;
});

export const addCalculation = createAsyncThunk('addCalculation', async (body) => {
    const response = await fetchHandler({
        url: '/financial-calculations/add-edit',
        method: 'POST',
        body
    });
    return response;
});

export const removeCalculation = createAsyncThunk('removeCalculation', async (guid) => {
    const response = await fetchHandler({
        url: '/financial-calculations/remove',
        method: 'DELETE',
        body: { guid }
    });
    return response;
});

export const financialCalculationsSlice = createSlice({
    name: 'financialCalculations',
    initialState: {
        recentCalculations: []
    },
    extraReducers: {
        [fetchCalculations.fulfilled]: (state, action) => {
            state.recentCalculations = action?.payload?.data?.result || [];
        }
    }
});

export default financialCalculationsSlice.reducer;

import React, { useReducer, useEffect } from "react";

import CalendarHead from "./calendar-head";
import CalendarDates from "./calendar-dates";
import CalendarMonths from "./calendar-months";
import CalendarYears from "./calendar-years";

import { pickerTypes } from "./--constants";

import "./index.scss";

const initialState = {
    calendarType: pickerTypes.date,
    dt: null,
    dateList: [],
    apiDates: []
}

const reducer = (state, payload) => {
	return {...state, ...payload};
}

const Calendar = ({
    view = 'month',
    type = pickerTypes.date, 
    value = null,
    onSelect = () => {},
    renderContent = ()=>null,
    onDtChange = () => {},
    onListReady = () => {},
    className = ""
}) => {
    const [{ calendarType, dt, dateList, apiDates }, dispatch] = useReducer(reducer,initialState);

    useEffect(() => {
        const dt = value ? new Date(value) : new Date();
        dispatch({dt});
    }, [value]);

    useEffect(() => {
        apiDates?.length && onListReady(apiDates.join(','));
    }, [apiDates, onListReady]);

    useEffect(() => {
        if(dt) {
            dispatch({year: dt.getFullYear()});
            dispatch({month: dt.getMonth()});
            dispatch({date: dt.getDate()});
            const year = dt.getFullYear();
            const month = dt.getMonth();
            let dateList = [];
            if(view === 'week') {
                const weekDaysArr = [...new Array(7)]; // 7 days
                const day = dt.getDay();
                dateList = weekDaysArr.map((i,index) => {
                    const ndt = new Date(year, month, dt.getDate()).setDate((index-day)+dt.getDate());
                    return { dateFull: new Date(ndt) }
                });
            } else {
                const thisMonthStartDay = new Date(year, month, 1).getDay();
                const monthDaysArr = [...new Array(42)]; // 42 = 6 weeks
                dateList = monthDaysArr.map((i,index) => {
                    const ndt = new Date(year, month, 1).setDate(index-(thisMonthStartDay-1));
                    return { dateFull: new Date(ndt) }
                });
            }
            dispatch({apiDates: [dateList[0]?.dateFull.toDateString(), dateList[dateList.length-1]?.dateFull.toDateString()]});
            dispatch({dateList});
        }
    }, [dt, view]);

    useEffect(() => {
        dispatch({calendarType: type})
    }, [type]);

    const calendarHeadProps = {
        dt,
        onChange: (date, month, year) => {
            const ndt = new Date(dt);
            ndt.setDate(date);
            ndt.setMonth(month);
            ndt.setYear(year);
            onDtChange(ndt);
            dispatch({dt: ndt});
        },
        onMonthPanel: () => calendarType !== pickerTypes.month ? dispatch({calendarType: pickerTypes.month}) : false,
        onYearPanel: () => calendarType !== pickerTypes.year ? dispatch({calendarType: pickerTypes.year}) : false
    }

    const calendarDatesProps = {
        view,
        value,
        dt,
        dateList,
        onSelect: dateFull=> {
            onDtChange(dateFull);
            dispatch({dt: dateFull});
            onSelect(dateFull);
        },
        renderContent
    }

    const calendarMonthsProps = {
        dt,
        onSelect: month => {
            const ndt = new Date(dt);
            ndt.setDate(1);
            ndt.setMonth(month);
            onDtChange(ndt);
            dispatch({dt: ndt, calendarType: type === pickerTypes.date ? pickerTypes.date : pickerTypes.month});
        }
    }

    const calendarYearsProps = {
        dt,
        onSelect: year => {
            const ndt = new Date(dt);
            ndt.setDate(1);
            ndt.setFullYear(year);
            onDtChange(ndt);
            dispatch({dt: ndt, calendarType: type !== pickerTypes.year ? pickerTypes.date : pickerTypes.year});
        }
    }

    const renderCalendarBody = () => {
        switch(calendarType) {
            case pickerTypes.month :
                return <CalendarMonths {...calendarMonthsProps} />;
            case pickerTypes.year :
                return <CalendarYears {...calendarYearsProps} />;
            default :
                return <CalendarDates {...calendarDatesProps} />
        }
    }

    return <div className={`calendar view-${view} ${className}`}>
        <CalendarHead {...calendarHeadProps} />
        {renderCalendarBody()}
    </div>
}

export default Calendar;
import React, { useEffect, useRef } from 'react';
import { DatePicker, MonthPicker } from '../../components';
import "./index.scss";
import Editor from './editor';

const FormField = ({className="", id=null, name="", label=null, type="text", options=[], placeholder="Type here", validation={
    required: false
}, value='', checked='', icon=null, tabIndex=1, checkedColor=null, disabled=false, onChange=()=>{}, focus=false, onFocus=()=>{}, list=null}) => {
    const ref = useRef();

    useEffect(() => {
        let timeout = null;
        timeout = setTimeout(() => {            
            focus && ref?.current.focus();
        }, 10);
        return () => {
            timeout && clearTimeout(timeout);
        }
    }, [focus]);

    const commonProps = {
        ref,
        id,
        className: 'form-control',
        tabIndex,
        name,
        placeholder,
        onFocus,
        disabled
    }

    const renderFormField = () => {
        switch(type) {
            case 'quill' :
                return <Editor name={name} value={value} onChange={value=>onChange(value)} />;
            case "datepicker": 
                return <DatePicker value={value} disabled={disabled} tabIndex={tabIndex} onChange={value=>onChange(value)} />
            case "monthpicker": 
                return <MonthPicker value={value} disabled={disabled} tabIndex={tabIndex} onChange={value=>onChange(value)} />
            case "select":
                return <select {...commonProps} value={value} onChange={e=>onChange(e?.target?.value)}>
                    {options.map(i=><option key={i?.guid} value={i?.value}>{i?.label}</option>)}
                </select>;
            case "checkbox":
                return <div className='hidden-control'>
                    <input type={type} {...commonProps} value={name} checked={value} onChange={e=>onChange(e?.target?.checked)} />
                    {label?<div className='form-label'><div className='input-ui' style={checkedColor&&value?{backgroundColor: checkedColor}:{}} /><div className='input-label'>{label}</div></div>:''}
                </div>
            case "radio":
                return <div className='hidden-control'>
                    <input type={type} {...commonProps} value={value} checked={value===checked} onChange={e=>onChange(e?.target?.value)} />
                    {label?<div className='form-label'><div className='input-ui' /><div className='input-label'>{label}</div></div>:''}
                </div>
            case "textarea":
                return <textarea {...commonProps} value={value} onChange={e=>onChange(e?.target?.value)} />
            case "file" :
                return <input {...commonProps} onChange={e=>onChange(e?.nativeEvent?.target?.files)} type={type} autoComplete="off" />;
            default :
                return <input {...commonProps} value={value} onChange={e=>onChange(e?.target?.value)} type={type} autoComplete="off" list={list} />;
        }
    }

    const renderFormGroupInner = () => <>
        {label?<div className='form-label'>{label}</div>:''}
        <div className='form-field'>
            {renderFormField()}
            {icon ? <div className='icon'>{icon}</div> : null}
        </div>
        {validation?.required?<div className='form-error'>Required</div>:null}
    </>

    return ['quill'].includes(type) ? <div className={`form-group ${className} input-${type}`}>{renderFormGroupInner()}</div> : <label className={`form-group ${className} input-${type}`}>{renderFormGroupInner()}</label>;
};

export default FormField;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormField } from '../../ui-components';
import { searchEvents } from '../../store/slices/app';
import { setDt, gotoAndFocusEvent, resetFocusEvent } from '../../store';
import { getEventPath } from '../../utils';

const HeaderSearch = () => {
    const reduxDispatch = useDispatch();
    const { eventTypes, focusEvent } = useSelector(state => state?.app);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [result, setResult] = useState([]);

    const ref = useRef();

    useEffect(() => {
        const listener = e => {
            const path = getEventPath(e);
            const innerClick = path.includes(ref?.current);
            if(!innerClick) {
                setOpen(false);
                setSearch('');
                setResult([]);
            }
        }
        window.addEventListener('click', listener);
        return () => {
            window.removeEventListener('click', listener);
        }
    })

    useEffect(() => {
        let timeout = null;
        if(search) {
                timeout = setTimeout(() => {
                reduxDispatch(searchEvents(search)).then(e=>{
                    if(e?.type.includes("/fulfilled")) {
                        setResult(e?.payload?.data);
                    }
                });
            }, 500);
        }
        return ()=> {
            timeout && clearTimeout(timeout);
        }
    }, [search, reduxDispatch]);

    const openSearch = () => {
        setOpen(!open);
        if(open) {
            setSearch('');
            setResult([]);
        }
    }

    const onResultItemClick = event => {
        const dt = new Date(event?.date);
        // dt.setFullYear(new Date().getFullYear());
        reduxDispatch(setDt(dt));
        reduxDispatch(gotoAndFocusEvent(event));
    }

    const onResetFocusEvent = () => {
        reduxDispatch(resetFocusEvent());
    }

    const renderSearchResult = () => {
        return result?.length ? <div className='search-result-wrap'>
            {result.map(i => {
                const type = eventTypes.find(ev=>ev?.label===i?.type);
                return<div key={i?.guid} className="result-item" onClick={()=>onResultItemClick(i)}>
                    <div className='details'>
                        <div className='result-item-label'>{i?.dateFull}{type?.company && i?.company && i?.company !== '' ? `- ${i?.company}` : ''}</div>
                        <div className='result-item-name'>{i?.name}</div>
                    </div>
                    <div className='icon' style={{backgroundColor: type?.color}}>{type?.short}</div>
                </div>;
            })}
        </div> : null;
    }

    return (
        <>
            <div className={`header-search ${open ? 'open' : ''}`} ref={ref}>
                <FormField type="text" value={search} onChange={setSearch} focus={open} />
                <button className='btn btn-light btn-cell scale-75' type='button' onClick={openSearch}>{open?<i className='icon-close' />:<i className='icon-search' />}</button>
                {renderSearchResult()}
            </div>
            {focusEvent && eventTypes ? <div className='focus-event-badge'>
                <span className='label' onClick={()=>onResultItemClick(focusEvent)}>{eventTypes?.find(i=>i?.label===focusEvent?.type)?.short}</span>
                <span className='close' onClick={onResetFocusEvent}><i className='icon-close' /></span>
            </div> : null}
        </>
    );
};

export default HeaderSearch;

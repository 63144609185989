import React, { useEffect, useState } from 'react';
import "./index.scss";

const Time = () => {
    const [dt, setDt] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => {
            setDt(new Date());
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    });
    const renderHours = () => {
        const hoursIn12 = dt.getHours()%12;
        const hour0To12 = hoursIn12 === 0 ? 12 : hoursIn12;
        return (hour0To12.toString()).padStart(2, '0');
    }
    const renderMinutes = () => {
        const minutes = dt.getMinutes();
        return (minutes.toString()).padStart(2, '0');
    }
    const renderSeconds = () => {
        const seconds = dt.getSeconds();
        return (seconds.toString()).padStart(2, '0');
    }
    const renderAmPm = () => {
        const hours = dt.getHours();
        const ampm = hours > 11 ? 'pm' : 'am';
        return ampm;
    }
    return (
        <div className='time-wrap'>
            <div className='time-hour'>{renderHours()}</div>
            <div className='time-minute'>{renderMinutes()}</div>
            <div className='time-second'>{renderSeconds()}</div>
            <div className='time-ampm'>{renderAmPm()}</div>
        </div>
    );
};

export default Time;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchHandler } from "../fetchHandler";

export const fetchContacts = createAsyncThunk("fetchContacts", async () => {
    const response = await fetchHandler({
        url: `/contacts/get-contacts`,
        method: "GET",
    });
    return response;
});

export const createUpdateContact = createAsyncThunk("createUpdateContact", async (body) => {
    const response = await fetchHandler({
        url: `/contacts/add-edit`,
        method: "POST",
        body,
    });
    return response;
});

export const deleteContact = createAsyncThunk("deleteContact", async (guid) => {
    const response = await fetchHandler({
        url: `/contacts/remove`,
        method: "DELETE",
        body: { guid }
    });
    return response;
});

export const contactsSlice = createSlice({
    name: "contacts",
    initialState: {
        fetching: false,
        contactsData: {},
    },
    extraReducers: {
        [fetchContacts.pending]: (state) => {
            state.fetching = true;
            state.contactsData = {};
        },
        [fetchContacts.fulfilled]: (state, action) => {
            state.fetching = false;
            state.contactsData = action?.payload?.data;
        },
        [createUpdateContact.fulfilled]: (state, action) => {
            const result = action?.payload?.result;
            const contactToUpdate = {
                ...result,
                photo: result?.photo,
            };
            const isExistingContact = !!state?.contactsData?.result?.find(
                (i) => i?.guid === contactToUpdate?.guid
            );
            state.contactsData = {
                ...state?.contactsData,
                result: isExistingContact
                    ? state?.contactsData?.result?.map((i) => {
                          return i?.guid === contactToUpdate?.guid
                              ? contactToUpdate
                              : i;
                      })
                    : [...state?.contactsData?.result, contactToUpdate],
            };
        },
        [deleteContact.fulfilled]: (state, action) => {
            const {
                payload: { status },
                meta: { arg },
            } = action;
            if (status === 200) {
                state.contactsData = {
                    ...state?.contactsData,
                    result: state?.contactsData?.result?.filter(
                        (i) => i?.guid !== arg
                    ),
                };
            }
        },
    },
});

export default contactsSlice.reducer;

export const ENCRIPTIONKEY = '51978bba521841cfa40a39c4ea853ece436731b1e4ed4d87b3245fb443528834';

export const LEAVE_TYPES = ["Casual", "Medical", "Optional", "LOP"];

export const VARIABLES = {
    PRIMARY: '#1E88E5',
    SECONDARY: '#1E2029',
    BG: '#263238',
    BGDARK: '#192226',
    BGMODAL: '#455A64',
    BGOPAQUE: 'rgba(38, 50, 56, 0.9)',
    BORDER: '#37474F',
    TEXT: '#FAFAFA',
    FONTFAMILY: 'Poppins_400Regular'
}

export const CURRENCY = {
    NAME: 'INR',
    SYMBOL: '₹',
    LANG: 'en-IN'
}

export const DATE = {
    LANG: 'en-IN'
}

export const TIME = {
    LANG: 'en-US',
    OPTIONS: { hour: '2-digit', minute: '2-digit' }
}

export const VERIFY = {
    VERIFYING: 'VERIFYING',
    VERIFIED: 'VERIFIED',
    NOT_VERIFIED: 'NOT_VERIFIED'
}
import React from "react";
import { prePad } from "../helper";
import UtilToggleTheme from "./toggle-theme";
import EXIF from "exif-js";
import { googleMapsApiKey } from "../constants";

export const generateGuid = () =>
    Date.now().toString(36) + Math.random().toString(36).substr(2);

export const getAllParentNodes = (el) => {
    const els = [];
    while (el) {
        els.unshift(el);
        el = el.parentNode;
    }
    return els;
};

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
              result[3],
              16
          )}`
        : null;
};

export const getEventPath = (e) => e.composedPath && e.composedPath();

export const extractDate = (dt) => {
    const ndt = dt instanceof Date ? dt : new Date(dt);
    return {
        year: ndt.getFullYear(),
        month: ndt.getMonth(),
        date: ndt.getDate(),
        day: ndt.getDay(),
        hour: ndt.getHours(),
        minute: ndt.getMinutes(),
        second: ndt.getSeconds(),
        zerotime: new Date(
            ndt.getFullYear(),
            ndt.getMonth(),
            ndt.getDate()
        ).getTime(),
        time: ndt.getTime(),
        yyyymmdd: `${ndt.getFullYear()}${prePad(ndt.getMonth())}${prePad(
            ndt.getDate()
        )}`,
    };
};

export const uniqueArray = (array) => {
    return [...new Set(array)];
};

export const copyToClipBorad = (textToCopy) =>
    navigator.clipboard.writeText(textToCopy);

const renderSpans = (n) => [...new Array(n)].map((e, i) => <span key={i} />);

export const MemoIconSearch = (negative = false) => (
    <div className={`memo-icon-search ${negative ? "negative" : ""}`}>
        {renderSpans(2)}
    </div>
);
export const MemoIconArrowUp = (negative = false) => (
    <div className={`memo-icon-arrow-up ${negative ? "negative" : ""}`} />
);
export const MemoIconArrowDown = (negative = false) => (
    <div className={`memo-icon-arrow-down ${negative ? "negative" : ""}`} />
);
export const MemoIconArrowLeft = (negative = false) => (
    <div className={`memo-icon-arrow-left ${negative ? "negative" : ""}`} />
);
export const MemoIconArrowRight = (negative = false) => (
    <div className={`memo-icon-arrow-right ${negative ? "negative" : ""}`} />
);
export const MemoIconClose = (negative = false) => (
    <div className={`memo-icon-close ${negative ? "negative" : ""}`} />
);
export const MemoIconTick = (negative = false) => (
    <div className={`memo-icon-tick ${negative ? "negative" : ""}`} />
);
export const MemoIconPlus = (negative = false) => (
    <div className={`memo-icon-plus ${negative ? "negative" : ""}`} />
);
export const MemoIconHamburger = (negative = false) => (
    <div className={`memo-icon-hamburger ${negative ? "negative" : ""}`} />
);
export const MemoIconCalendar = (negative = false) => (
    <div className={`memo-icon-calendar ${negative ? "negative" : ""}`}>
        {renderSpans(7)}
    </div>
);
export const MemoIconTime = (negative = false) => (
    <div className={`memo-icon-time ${negative ? "negative" : ""}`}>
        {renderSpans(2)}
    </div>
);
export const MemoIconDetails = (negative = false) => (
    <div className={`memo-icon-details ${negative ? "negative" : ""}`}>
        {renderSpans(4)}
    </div>
);
export const MemoIconTrash = (negative = false) => (
    <div className={`memo-icon-trash ${negative ? "negative" : ""}`}>
        {renderSpans(4)}
    </div>
);
export const MemoIconEdit = (negative = false) => (
    <div className={`memo-icon-edit ${negative ? "negative" : ""}`}>
        {renderSpans(2)}
    </div>
);
export const MemoIconWeekColumns = (negative = false) => (
    <div className={`memo-icon-week-columns ${negative ? "negative" : ""}`}>
        {renderSpans(3)}
    </div>
);
export const MemoIconSchedule = (negative = false) => (
    <div className={`memo-icon-schedule ${negative ? "negative" : ""}`}>
        {renderSpans(2)}
    </div>
);

export const usePrevious = (value) => {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const removeDuplicateObjects = (arr, key) => {
    const uniqueObjects = [];
    const objectIds = new Set();

    for (const obj of arr) {
        if (!objectIds.has(obj[key])) {
            objectIds.add(obj[key]);
            uniqueObjects.push(obj);
        }
    }

    return uniqueObjects;
};

export const getFileSize = (sizeInBytes) => {
    const toMb = (sizeInBytes / (1024 * 1024)).toFixed(2);
    const tokb = (sizeInBytes / 1024).toFixed(2);
    return toMb < 1
        ? { value: tokb, label: "kb" }
        : { value: toMb, label: "mb" };
};

export const extractExifDataFromFile = async (file) => {
    return new Promise((resolve, reject) => {
        EXIF.getData(file, function () {
            const exifdata = EXIF.getAllTags(this);

            if (exifdata) {
                resolve(exifdata);
            } else {
                reject();
            }
        });
    });
};

export const extractCoordinatesFromFile = async (file) => {
    return new Promise((resolve, reject) => {
        EXIF.getData(file, function () {
            const lat = EXIF.getTag(this, "GPSLatitude");
            const lng = EXIF.getTag(this, "GPSLongitude");

            if (lat && lng) {
                const latDecimal = lat[0] + lat[1] / 60 + lat[2] / 3600;
                const lngDecimal = lng[0] + lng[1] / 60 + lng[2] / 3600;
                resolve({
                    latitude: latDecimal,
                    longitude: lngDecimal,
                });
            } else {
                reject();
            }
        });
    });
};

export const findPlaceUsingLatLgnFromGoogleMaps = async (file) => {
    return await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${file?.latitude},${file?.longitude}&key=${googleMapsApiKey}`
    )
        .then((res) => res.json())
        .then((data) => {
            const {
                results: [{ formatted_address }],
            } = data;
            return formatted_address;
        });
};

export const renderThumbnailFromInputFile = (file, boundBox) => {
    if (!boundBox || boundBox.length !== 2) {
        throw new Error("You need to give the boundBox");
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
        throw new Error("Context not available");
    }

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onerror = reject;
        img.onload = function () {
            const sizeCheck =
                img.width > boundBox[0] || img.height > boundBox[0];
            const scaleRatio =
                Math.min(...boundBox) / Math.max(img.width, img.height);
            const w = sizeCheck ? img.width * scaleRatio : img.width;
            const h = sizeCheck ? img.height * scaleRatio : img.height;
            canvas.width = w;
            canvas.height = h;
            ctx.drawImage(img, 0, 0, w, h);
            return resolve(canvas.toDataURL(file.type));
        };
        img.src = window.URL.createObjectURL(file);
    });
};

export const replaceFirstNOccurrences = (
    inputString,
    targetCharacter,
    replacementCharacter,
    n = 2
) => {
    let occurrences = 0;
    const resultArray = [];

    for (let i = 0; i < inputString?.length; i++) {
        if (inputString[i] === targetCharacter && occurrences < n) {
            resultArray.push(replacementCharacter);
            occurrences++;
        } else {
            resultArray.push(inputString[i]);
        }
    }

    return resultArray.join("");
};

export const jsonExifdata = (raw) => {
    const getFromRaw = (key) => {
        return raw[key] ? raw[key] : null;
    };
    const getDummyDate = () => {
        const dt = new Date();
        return `${dt.getFullYear()}:${prePad(dt.getMonth()+1)}:${prePad(dt.getDate())} ${prePad(dt.getHours())}:${prePad(dt.getMinutes())}:${prePad(dt.getSeconds())}`;
    }
    return {
        DateTime: getFromRaw("DateTime") || getDummyDate(),
        GPSInfoIFDPointer: getFromRaw("GPSInfoIFDPointer"),
        Model: getFromRaw("Model"),
        YCbCrPositioning: getFromRaw("YCbCrPositioning"),
        ResolutionUnit: getFromRaw("ResolutionUnit"),
        YResolution: getFromRaw("YResolution"),
        ExifIFDPointer: getFromRaw("ExifIFDPointer"),
        XResolution: getFromRaw("XResolution"),
        Make: getFromRaw("Make"),
        ColorSpace: getFromRaw("ColorSpace"),
        DateTimeDigitized: getFromRaw("DateTimeDigitized") || getDummyDate(),
        FNumber: getFromRaw("FNumber"),
        FocalLength: getFromRaw("FocalLength"),
        ApertureValue: getFromRaw("ApertureValue"),
        ExposureMode: getFromRaw("ExposureMode"),
        SubsecTimeDigitized: getFromRaw("SubsecTimeDigitized"),
        PixelYDimension: getFromRaw("PixelYDimension"),
        FocalLengthIn35mmFilm: getFromRaw("FocalLengthIn35mmFilm"),
        SceneCaptureType: getFromRaw("SceneCaptureType"),
        SceneType: getFromRaw("SceneType"),
        SubsecTimeOriginal: getFromRaw("SubsecTimeOriginal"),
        ExposureProgram: getFromRaw("ExposureProgram"),
        WhiteBalance: getFromRaw("WhiteBalance"),
        PixelXDimension: getFromRaw("PixelXDimension"),
        SubsecTime: getFromRaw("SubsecTime"),
        ShutterSpeedValue: getFromRaw("ShutterSpeedValue"),
        MeteringMode: getFromRaw("MeteringMode"),
        DateTimeOriginal: getFromRaw("DateTimeOriginal") || getDummyDate(),
        ComponentsConfiguration: getFromRaw("ComponentsConfiguration"),
        Flash: getFromRaw("Flash"),
        ExifVersion: getFromRaw("ExifVersion"),
        InteroperabilityIFDPointer: getFromRaw("InteroperabilityIFDPointer"),
        BrightnessValue: getFromRaw("BrightnessValue"),
        ISOSpeedRatings: getFromRaw("ISOSpeedRatings"),
        SensingMethod: getFromRaw("SensingMethod"),
        FlashpixVersion: getFromRaw("FlashpixVersion"),
        ExposureTime: getFromRaw("ExposureTime"),
        GPSDateStamp: getFromRaw("GPSDateStamp"),
        GPSAltitudeRef: getFromRaw("GPSAltitudeRef"),
        GPSLongitudeRef: getFromRaw("GPSLongitudeRef"),
        GPSLongitude: getFromRaw("GPSLongitude"),
        GPSProcessingMethod: getFromRaw("GPSProcessingMethod"),
        GPSLatitudeRef: getFromRaw("GPSLatitudeRef"),
        GPSTimeStamp: getFromRaw("GPSTimeStamp"),
        GPSAltitude: getFromRaw("GPSAltitude"),
        GPSLatitude: getFromRaw("GPSLatitude"),
        thumbnail: getFromRaw("thumbnail"),
    };
};

export { UtilToggleTheme };

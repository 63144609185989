import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { monthLabels } from '../../constants';
import Time from '../time';
import { setDt, toggleDashboardNav } from '../../store';
import HeaderSearch from './header-search';
import { Tooltip } from '../../ui-components';
import ComponentMainNav2 from '../main-nav';
import "./index.scss";
import { prePad } from '../../helper';

const ComponentHeader = () => {
    const reduxDispatch = useDispatch();
    const { dt, view } = useSelector(state => state?.dashboard);

    const today = prePad(new Date().getDate());

    const month = monthLabels[dt.getMonth()];
    const year = dt.getFullYear();

    const setDtFn = (dt) => {
        reduxDispatch(setDt(dt));
    }

    const onMonthPrev = () => {
        if(view!=='week') {
            const month = dt?.getMonth();
            const year = dt?.getFullYear();
            const newMonth = month-1;
            setDtFn(new Date(year, newMonth, 1));
        } else {
            const ndt = new Date(dt);
            ndt.setDate(ndt.getDate()-7);
            setDtFn(ndt);
        }
    }

    const onMonthNext = () => {
        if(view!=='week') {
            const month = dt?.getMonth();
            const year = dt?.getFullYear();
            const newMonth = month+1;
            setDtFn(new Date(year, newMonth, 1));
        } else {
            const ndt = new Date(dt);
            ndt.setDate(ndt.getDate()+7);
            setDtFn(ndt);
        }
    }

    return (
        <div className='app-header'>
            <div className='header-container'>
                <ComponentMainNav2 className='logo-calendar' label={<div className='logo-date'>{today}</div>} />
                <button className='btn btn-light btn-cell header-menu' type='button' onClick={()=>reduxDispatch(toggleDashboardNav())}>{<i className="icon-hamburger" />}</button>
                <button className='btn btn-secondary header-today' type='button' onClick={()=>setDtFn(new Date())}>Today</button>
                <Tooltip title={`Previous ${view==='week' ? 'week' : 'month'}`} disableChild={false}>
                    <button className='btn btn-light btn-cell header-prev' type='button' onClick={onMonthPrev}><i className='icon-chevron-left' /></button>
                </Tooltip>
                <Tooltip title={`Next ${view==='week' ? 'week' : 'month'}`} disableChild={false}>
                    <button className='btn btn-light btn-cell header-next' type='button' onClick={onMonthNext}><i className='icon-chevron-right' /></button>
                </Tooltip>
                <div className='header-month'>{month}</div>
                <div className='header-year'>{year}</div>
                
                <div className='header-spacer'></div>
                
                <HeaderSearch />
                
                <div className='header-time'><Time /></div>
            </div>
        </div>
    );
};

export default ComponentHeader;
import React from 'react';
import './index.scss';

const ComponentSidePanel = ({show=false, className='', onClose=()=>{}, children}) => {
    return (
        <div className={`side-panel-wrap ${className} ${show ? 'open' : ''}`}>
            <div className='side-panel-overlay' onClick={onClose} />
            <div className='side-panel'>
                {children}
            </div>
        </div>
    );
};

export default ComponentSidePanel;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { removeDuplicateObjects } from '../../utils';
import { fetchHandler } from '../fetchHandler';

export const fetchExpenses = createAsyncThunk('fetchExpenses', async (body) => {
    const response = await fetchHandler({
        url: `/expenses/all`,
        method: 'POST',
        body
    });
    return response?.data;
});

export const addExpense = createAsyncThunk('addExpense', async (body) => {
    const response = await fetchHandler({
        url: '/expenses',
        method: 'POST',
        body
    });
    return response?.data;
});

export const deleteExpense = createAsyncThunk('deleteExpense', async (body) => {
    const response = await fetchHandler({
        url: '/expenses',
        method: 'DELETE',
        body
    });
    return response?.data;
});

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        expenseData: {},
        dt: new Date(new Date().toDateString()),
        view: 'week',
        editEventData: null,
        dashboardNavOpen: false
    },
    reducers: {
        setDt: (state, action) => {
            state.dt = action.payload;
        },
        setEditEventData: (state, action) => {
            state.editEventData = action.payload;
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        toggleDashboardNav: (state) => {
            state.dashboardNavOpen = !state.dashboardNavOpen;
        }
    },
    extraReducers: {
        [fetchExpenses.pending]: (state, action) => {
            if(action?.meta?.arg?.account !== state?.expenseData?.account) {
                state.expenseData = {}
            };
        },
        [fetchExpenses.fulfilled]: (state, action) => {
            const newExpenseData = state.expenseData?.account === action.payload?.account ? {...action.payload, data: [...state.expenseData?.data, ...action.payload?.data]} : action.payload;
            state.expenseData = {...newExpenseData, data: removeDuplicateObjects(newExpenseData?.data, 'guid')};
        },
        [addExpense.fulfilled]: (state, action) => {
            const newExpenseAdded = action.payload?.data;
            const isUpdate = action?.payload?.message === "Expense updated";
            if(isUpdate) {
                const transaction = action?.meta?.arg;
                state.expenseData = {
                    ...state.expenseData,
                    currentBalance: newExpenseAdded?.currentBalance || state?.expenseData?.currentBalance,
                    initialBalance: newExpenseAdded?.initialBalance || state?.expenseData?.initialBalance,
                    totalCredits: newExpenseAdded?.totalCredits || state?.expenseData?.totalCredits,
                    totalDebits: newExpenseAdded?.totalDebits || state?.expenseData?.totalDebits,
                    data: state.expenseData?.data?.map(i => {
                        return i?.guid === transaction?.guid ? {
                            ...i,
                            amount: transaction?.amount,
                            comment: transaction?.comment
                        } : i;
                    }).sort((a,b)=>(new Date(b.date).getTime()-new Date(a.date).getTime())) 
                };
            } else {
                const transaction = action?.payload?.data;
                const lastTransaction = state.expenseData?.data[0];
                const newTransaction = {
                    ...transaction,
                    currentBalance: transaction?.type === 'DEBIT' ? (Number(lastTransaction?.balance) - Number(transaction?.amount)) : (Number(lastTransaction?.balance) + Number(transaction?.amount))
                }
                state.expenseData = {
                    ...state.expenseData,
                    currentBalance: newExpenseAdded?.currentBalance || state?.expenseData?.currentBalance,
                    initialBalance: newExpenseAdded?.initialBalance || state?.expenseData?.initialBalance,
                    totalCredits: newExpenseAdded?.totalCredits || state?.expenseData?.totalCredits,
                    totalDebits: newExpenseAdded?.totalDebits || state?.expenseData?.totalDebits,
                    data: [newTransaction, ...state.expenseData?.data].sort((a,b)=>(new Date(b.date).getTime()-new Date(a.date).getTime()))
                };
            }
        },
        [deleteExpense.fulfilled]: (state, action) => {
            const { status, data } = action?.payload;

            let currentBalance = state?.expenseData?.currentBalance;
            let initialBalance = state?.expenseData?.initialBalance;
            let totalCredits = state?.expenseData?.totalCredits;
            let totalDebits = state?.expenseData?.totalDebits;

            if(data?.type==="DEBIT") {
                currentBalance = state?.expenseData?.currentBalance + data?.amount;
                totalDebits = state?.expenseData?.totalDebits - data?.amount;
            }

            if(data?.type==="CREDIT") {
                currentBalance = state?.expenseData?.currentBalance - data?.amount;
                totalCredits = state?.expenseData?.totalCredits - data?.amount;
            }

            if(status === 200) {
                state.expenseData = {
                    ...state.expenseData,
                    currentBalance,
                    initialBalance,
                    totalCredits,
                    totalDebits,
                    data: [...state.expenseData?.data?.filter(i => i?.guid !== data?.guid)]
                }
            }
        }
    }
});

export const { setDt, setEditEventData, setView, toggleDashboardNav } = dashboardSlice.actions;

export default dashboardSlice.reducer

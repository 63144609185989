import React from 'react';
import { monthLabels } from "./--constants";

const CalendarHead = ({dt=null, onChange, onMonthPanel, onYearPanel}) => {
    const onMonthPrev = () => {
        const month = dt?.getMonth();
        const year = dt?.getFullYear();
        const newMonth = month-1;
        const newYear = newMonth === -1 ? year-1 : year;
        onChange(1, newMonth, newYear)
    }
    const onMonthNext = () => {
        const month = dt?.getMonth();
        const year = dt?.getFullYear();
        const newMonth = month+1;
        const newYear = newMonth === 12 ? year+1 : year;
        onChange(1, newMonth, newYear)
    }

    return dt ? <div className='calendar-head'>
        <div className='head-month' onClick={()=>onMonthPanel(dt?.getMonth())}>{monthLabels[dt?.getMonth()]}</div>
        <div className='head-year' onClick={()=>onYearPanel(dt?.getFullYear())}>{dt?.getFullYear().toString()}</div>
        <div className='head-spacer' />
        <div className='head-prev' onClick={onMonthPrev} />
        <div className='head-next' onClick={onMonthNext} />
    </div> : null;
}

export default CalendarHead;
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from "react-dom";
import { getEventPath } from '../../utils';
import "./index.scss";
import { monthLabels } from '../../constants';
import { prePad } from '../../helper';

const PortalMonthPicker = ({children}) => {
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        el.id = 'monthpicker-root';
        let monthpickerRoot = document.querySelector('#monthpicker-root');
        !monthpickerRoot && document.body.appendChild(el);
        return () => {
            document.body.removeChild(el);
        }
    }, [el]);

    return ReactDOM.createPortal(
        children,
        el
    );
};

const MonthPicker = ({value=new Date(), placeholder, disabled=false, onFocus=()=>{}, name, onChange=()=>{}, tabIndex=1}) => {
    const [dt, setDt] = useState(new Date());
    const [open, setOpen] = useState(false);
    const [monthpickerRect, setMonthpickerRect] = useState(null);

    useEffect(() => {
        setDt(value);
    }, [value]);

    const openMP = e => {
        setMonthpickerRect(e.target.getBoundingClientRect());
        setOpen(true);
        onFocus();
    }

    const onSelectMonth = () => {
        onChange(dt);
        setOpen(false);
    }

    const ref = useRef();

    useEffect(() => {
        const listener = e => {
            const path = getEventPath(e);
            const monthpickerRoot = document.querySelector('#monthpicker-root');
            const innerClick = path.includes(ref?.current) || path.includes(monthpickerRoot);
            if(!innerClick && open) {
                setOpen(false);
            }
        }
        window.addEventListener('click', listener);
        return () => {
            window.removeEventListener('click', listener);
        }
    })

    return (
        <div ref={ref} className={`${disabled?'disabled':''}`}>
            <input className='form-control' disabled={disabled} tabIndex={tabIndex} value={`${monthLabels[dt?.getMonth()]} ${dt?.getFullYear()}`} onChange={()=>{}} placeholder={placeholder} onFocus={openMP} name={name} type="text" />
            {open?<PortalMonthPicker>
                <div className='monthpicker-wrap' style={{
                    top: (monthpickerRect?.top+monthpickerRect?.height) + 'px', 
                    left: ((monthpickerRect?.left + 310) > window?.innerWidth ? (window?.innerWidth - 320) : monthpickerRect?.left) + 'px'
                }}>
                    <div className='monthpicker-container'>
                        <div className='monthpicker-header'>
                            {monthLabels[dt?.getMonth()]} {dt?.getFullYear()}
                        </div>
                        <div className='monthpicker-body'>                            
                            <div className='monthpicker-months'>
                                {monthLabels?.map((label,index) => {
                                    return <div key={label} className={`monthpicker-month ${dt?.getMonth()===index?'current':''}`} onClick={()=>setDt(new Date(dt?.setMonth(index)))}>
                                        <div className='month-digit'>{prePad(index+1)}</div>
                                        <div className='month-name'>{label?.substring(0,3)}</div>
                                    </div>
                                })}
                            </div>
                            <div className='monthpicker-years'>
                                {[...new Array(9)]?.map((item, index) => {
                                    const yearValue = (dt?.getFullYear()-4)+index;
                                    return <div className={`monthpicker-year ${dt?.getFullYear()===yearValue?'current':''}`} key={index} onClick={()=>setDt(new Date(dt?.setFullYear(yearValue)))}>{yearValue}</div>
                                })}
                            </div>
                        </div>
                        <div className='monthpicker-footer'>
                            <button type="button" className="btn btn-secondary" onClick={()=>setOpen(false)}>Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={onSelectMonth}>Ok</button>
                        </div>
                    </div>
                </div>
            </PortalMonthPicker>:null}
        </div>
    );
};

export default MonthPicker;
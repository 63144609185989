import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import "./index.scss";

const PortalModal = ({children, open, className="", onClose=()=>{}}) => {
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        el.id = 'modal-root';
        document.body.prepend(el);
        return () => {
            document.body.removeChild(el);
        }
    }, [el]);

    useEffect(() => {
        const listener = e => {
            e?.key === "Escape" && onClose();
        }
        window.addEventListener('keyup', listener);
        return () => {
            window.removeEventListener('keyup', listener);
        }
    })

    const renderModal = () => {
        return <div className={`modal-wrap ${className} ${open?'open':''}`}>
            <div className='modal-overlay' onClick={onClose} />
            <div className='modal-dialog'>
                <div className='modal-container'>{children}</div>
            </div>
        </div>
    }

    return ReactDOM.createPortal(
        renderModal(),
        el
    );
};

const Modal = (props) => {
    return props?.open ? <PortalModal {...props} /> : null;
}

export default Modal;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchHandler } from '../fetchHandler';

export const fetchAncestry = createAsyncThunk('fetchAncestry', async () => {
    const response = await fetchHandler({
        url: '/ancestry',
        method: 'GET'
    });
    return response?.data;
});

export const updateProfileImage = createAsyncThunk('updateProfileImage', async (body) => {
    const response = await fetchHandler({
        url: '/ancestry/upload-profile-image',
        method: 'POST',
        body
    });
    return response;
});

export const addAncestry = createAsyncThunk('addAncestry', async (body) => {
    const response = await fetchHandler({
        url: '/ancestry/add',
        method: 'POST',
        body
    });
    return response?.data;
});

export const removeAncestry = createAsyncThunk('removeAncestry', async (body) => {
    const response = await fetchHandler({
        url: '/ancestry/remove',
        method: 'POST',
        body
    });
    return response?.data;
});

export const ancestrySlice = createSlice({
    name: 'ancestry',
    initialState: {
        events: []
    },
    extraReducers: {
        [fetchAncestry.fulfilled]: (state, action) => {
            const ancestry = action?.payload?.data || [];
            state.ancestry = ancestry;
        }
    }
});

export default ancestrySlice.reducer;

import React from 'react';
import './index.scss';

const Loading = ({className=''}) => {
    return (
        <div className={`loading-wrap ${className}`}>
            <div className='loading-container'>
                <span className='span-1' />
                <span className='span-2' />
                <span className='span-3' />
            </div>
        </div>
    );
};

export default Loading;
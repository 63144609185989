import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from "react-dom";
import Calendar from '../calendar';
import { getEventPath } from '../../utils';
import "./index.scss";

const PortalDatePicker = ({children}) => {
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        el.id = 'datepicker-root';
        let datepickerRoot = document.querySelector('#datepicker-root');
        !datepickerRoot && document.body.appendChild(el);
        return () => {
            document.body.removeChild(el);
        }
    }, [el]);

    return ReactDOM.createPortal(
        children,
        el
    );
};

const DatePicker = ({value=new Date(), placeholder, disabled=false, onFocus=()=>{}, name, onChange=()=>{}, tabIndex=1}) => {
    const [dt, setDt] = useState(new Date());
    const [open, setOpen] = useState(false);
    const [datepickerRect, setDatepickerRect] = useState(null);

    useEffect(() => {
        setDt(value);
    }, [value]);

    const openDP = e => {
        setDatepickerRect(e.target.getBoundingClientRect());
        setOpen(true);
        onFocus();
    }

    const onSelectDate = e => {
        setDt(e);
        onChange(e);
        setOpen(false);
    }

    const ref = useRef();

    useEffect(() => {
        const listener = e => {
            const path = getEventPath(e);
            const datepickerRoot = document.querySelector('#datepicker-root');
            const innerClick = path.includes(ref?.current) || path.includes(datepickerRoot);
            if(!innerClick && open) {
                setOpen(false);
            }
        }
        window.addEventListener('click', listener);
        return () => {
            window.removeEventListener('click', listener);
        }
    })

    return (
        <div ref={ref} className={`${disabled?'disabled':''}`}>
            <input className='form-control' disabled={disabled} tabIndex={tabIndex} value={dt.toDateString()} onChange={()=>{}} placeholder={placeholder} onFocus={openDP} name={name} type="text" />
            {open?<PortalDatePicker>
                <div className='datepicker-calendar' style={{
                    top: (datepickerRect?.top+datepickerRect?.height) + 'px', 
                    left: datepickerRect?.left + 'px'
                }}>
                    <Calendar type="date" display="inline" value={dt} onSelect={onSelectDate} />
                </div>
            </PortalDatePicker>:null}
        </div>
    );
};

export default DatePicker;
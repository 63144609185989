import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from '../../constants';
import { fetchHandler } from '../fetchHandler';
import { guid } from '../../helper';
import { extractDate } from '../../utils';

const processEvents = (events=[], meta) => {
    const { startDate:sd, endDate:ed } = meta?.arg;
    const [ startDate, endDate ] = [ new Date(sd), new Date(ed) ];
    const createOvertimeEvents = event => {
        const result = [];
        const maxDuration = 24*60;
        const loopFn = ev => {
            const duration = (ev?.timelineTime > -1 ? ev?.timelineTime : ev?.time) + (ev?.timelineDuration > -1 ? ev?.timelineDuration : ev?.duration);
            if(duration > maxDuration) {
                const newDate = new Date(ev?.timelineDate || ev?.date);
                newDate.setDate(newDate.getDate()+1);
                const timelineDuration = (duration - maxDuration);
                const newEvent = {...ev, overtimeEvent: true, timelineDate: newDate.toISOString(), timelineTime: 0, timelineDuration, guid2: guid()};
                const endingBeforeEndDate =  newDate.getTime() <= endDate.getTime();
                const startedBeforeStartDate = new Date(startDate.toDateString()).getTime() <= new Date(newDate.toDateString()).getTime();
                if(startedBeforeStartDate && endingBeforeEndDate) result.push(newEvent);
                if(endingBeforeEndDate && timelineDuration > maxDuration) loopFn(newEvent);
            }
        }
        loopFn(event);
        return result;
    }
    const overTimeEvents = [];
    const newEvents = events?.map(ev => {
        const maxDuration = 24*60;
        const duration = ev?.time + ev?.duration;
        if(duration > maxDuration) {
            const newEvents = createOvertimeEvents(ev);
            overTimeEvents.push(...newEvents);
        }
        return duration > maxDuration ? {...ev, timelineDate: ev?.date, overtimeEvent: true, timelineDuration: ev?.duration - (duration - maxDuration)} : ev;
    });
    return [...newEvents, ...overTimeEvents];
}

export const fetchEventTypes = createAsyncThunk('fetchEventTypes', async (params) => {
    const response = await fetch(`${apiUrl}/events/types`).then(res=>res.json());
    return response?.data;
});

export const fetchCompanies = createAsyncThunk('fetchCompanies', async () => {
    const response = await fetchHandler({
        url: '/companies/get-list',
        method: 'GET'
    });
    return response?.data;
});

export const addCompany = createAsyncThunk('addCompany', async (body) => {
    const response = await fetchHandler({
        url: '/companies/add-edit',
        method: 'POST',
        body
    });
    return response?.data;
});

export const removeCompany = createAsyncThunk('removeCompany', async (body) => {
    const response = await fetchHandler({
        url: '/companies/remove',
        method: 'POST',
        body
    });
    return response?.data;
});

export const fetchEvents = createAsyncThunk('fetchEvents', async (params) => {
    const startDate = params?.startDate;
    const endDate = params?.endDate;
    const eventsFilter = params?.eventsFilter;
    const companiesFilter = params?.companiesFilter;
    const response = await fetchHandler({
        url: '/events',
        method: 'POST',
        body: {
            startDate,
            endDate,
            eventsFilter,
            companiesFilter
        }
    });
    return response?.data;
});

export const fetchEventsByType = createAsyncThunk('fetchEventsByType', async (params) => {
    const type = params?.type;
    const response = await fetchHandler({
        url: `/events/type/${type}`,
        method: 'GET'
    });
    const data = { ...response?.data, events: response?.data?.events.map(event => {
        const extractDt = extractDate(event?.dt);
        return {
            ...event,
            day: extractDt?.day,
            date: extractDt?.date,
            month: extractDt?.month,
            year: extractDt?.year
        }
    })}
    return data;
});

export const fetchTodayEvents = createAsyncThunk('fetchTodayEvents', async (params) => {
    const response = await fetchHandler({
        url: '/events/today',
        method: 'GET'
    });
    return response?.data;
});

export const searchEvents = createAsyncThunk('searchEvents', async (query) => {
    const response = await fetchHandler({
        url: `/events/search/${query}`,
        method: 'GET'
    });
    return response?.data;
});

export const mockRecords = createAsyncThunk('addEvent', async (body) => {
    const response = await fetchHandler({
        url: '/events/bulk-upload',
        method: 'POST',
        body
    });
    return response?.data;
});

export const addEvent = createAsyncThunk('addEvent', async (body) => {
    const response = await fetchHandler({
        url: '/events/add',
        method: 'POST',
        body
    });
    return response?.data;
});

export const removeEvent = createAsyncThunk('removeEvent', async (body) => {
    const response = await fetchHandler({
        url: '/events/remove',
        method: 'POST',
        body
    });
    return response?.data;
});

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        theme: 'app-light',
        navOpen: false,
        events: [],
        todayEvents: [],
        fetchEventsMeta: {},
        eventTypes: [],
        filters: {events: [], companies: []},
        focusEvent: null,
        prevEventType: 'BIRTHDAY',
        prevCompany: '',
        companies: [],
        oldCompaniesOnAddTask: false
    },
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        gotoAndFocusEvent: (state, action) => {
            state.focusEvent = action.payload;
        },
        resetFocusEvent: (state) => {
            state.focusEvent = null;
        },
        setSettingsItem: (state, action) => {
            const keys = Object.keys(action?.payload);
            keys.forEach(key => {
                state[key] = action?.payload[key];
            })
        },
        toggleNav: (state) => {
            state.navOpen = !state.navOpen;
        }
    },
    extraReducers: {
        [fetchEventTypes.fulfilled]: (state, action) => {
            const eventTypes = action?.payload?.data || [];
            state.eventTypes = eventTypes;
            state.filters = {...state.filters, events: eventTypes.map(i=>i?.label)};
        },
        [fetchCompanies.fulfilled]: (state, action) => {
            const companies = action?.payload?.result || [];
            state.companies = companies;
            state.filters = {...state.filters, companies: companies?.map(i=>i?.tag)};
        },
        [addCompany.fulfilled]: (state, action) => {
            const isEditing = action?.meta?.arg?.guid;
            if(action?.payload?.status === 200) {
                const newCompanyList = isEditing ? [...state?.companies.filter(i=>i?.guid!==isEditing), action?.payload] : [...state?.companies, action?.payload];
                state.companies = newCompanyList;
                state.filters = {...state.filters, companies: newCompanyList};
            }
        },
        [removeCompany.fulfilled]: (state, action) => {
            const newCompanyList = [...state.companies.filter(e => e?.guid !== action.payload?.guid)];
            state.companies = newCompanyList;
            state.filters = {...state.filters, companies: newCompanyList};
        },
        [fetchEvents.pending]: (state) => {
            state.events = [];
        },
        [fetchEvents.fulfilled]: (state, action) => {
            const events = processEvents(action?.payload?.events, action?.meta);
            state.fetchEventsMeta = action?.meta;
            state.events = events;
        },
        [fetchTodayEvents.fulfilled]: (state, action) => {
            state.todayEvents = action?.payload?.events;
        },
        [addEvent.fulfilled]: (state, action) => {
            const newEvent = action.payload?.data;
            const filterEvents = state.events.filter(ev => ev?.guid !== newEvent?.guid);
            state.prevEventType = newEvent?.type;
            if(newEvent?.company) state.prevCompany = newEvent?.company;
            const events = processEvents([newEvent], state.fetchEventsMeta);
            state.events = [...filterEvents, ...events];
        },
        [removeEvent.fulfilled]: (state, action) => {
            state.events = [...state.events.filter(e => e?.guid !== action.payload?.data?.guid)];
        }
    }
});

export const { setTheme, setFilters, gotoAndFocusEvent, resetFocusEvent, setSettingsItem, toggleNav } = appSlice.actions;

export default appSlice.reducer;

import React, { useState } from 'react';
import "./index.scss";

const ConfirmButtonCell = ({
    icon=null, 
    yesBtnClasses='btn btn-cell btn-danger confirm-yes', 
    yesBtnLabel=<i className='icon-tick' />, 
    noBtnClasses='btn btn-cell btn-primary confirm-no', 
    noBtnLabel=<i className='icon-close' />, 
    classes='', 
    wrapperClasses='', 
    onConfirm=()=>{}
}) => {
    const [confirm, setConfirm] = useState(false);
    return confirm ? <div className={`confirm-cell-box ${wrapperClasses}`}>
        <button type='button' onClick={()=>{
            setConfirm(false);
            onConfirm();
        }} className={yesBtnClasses}>{yesBtnLabel}</button>
        <button type='button' onClick={()=>setConfirm(false)} className={noBtnClasses}>{noBtnLabel}</button>
    </div> : <button type='button' onClick={()=>setConfirm(true)} className={`${classes} ${wrapperClasses}`}>{icon}</button>;
};

export default ConfirmButtonCell;

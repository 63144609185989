import React from 'react';
import { FormField } from '../../ui-components';

const FilterGroup = ({title='Title', filters=[], list=[], onClearFilters=()=>{}, onSelectAllFilters=()=>{}, onChange=()=>{}}) => {
    return (
        <div className='calendar-filter'>
            {/* Type Filter */}
            <div className='d-flex mb-2'>                        
                <h5 className='flex-fill m-0'>{title}</h5>
                <div className={filters?.length?'cursor-pointer':'disabled'}>
                    <div onClick={onClearFilters}>Clear all</div>
                </div>
            </div>
            <div className='filter-wrap'>
                <div className={filters?.length!==list?.length?'cursor-pointer':'disabled'}>
                    <div onClick={onSelectAllFilters}>Select all</div>
                </div>
                {list?.map((item, index)=><div className='filter-item' key={index}>
                    <FormField className="col" checkedColor={item?.color} type='checkbox' value={filters?.indexOf(item?.value)>-1} onChange={()=>onChange(item?.value)} label={item?.label} />
                </div>)}
            </div>
        </div>
    );
};

export default FilterGroup;
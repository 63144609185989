import React from 'react';

import { monthLabels } from "./--constants";

const CalendarMonths = ({dt=null, onSelect = () => false}) => {
    const renderMonthLabels = () => {
        return monthLabels.map((item, index) => {
            const isMonthCurrent = new Date().getMonth() === index ? 'this-month' : '';
            const isMonthSelected = dt.getMonth() === index ? 'selected' : '';
            return <div className={`month ${isMonthCurrent} ${isMonthSelected}`} key={item} onClick={()=>onSelect(index)}>
                <div className='month-label'>{item.substring(0,3)}</div>
            </div>
        });
    }

    return <div className='calendar-months'>
        {renderMonthLabels()}
    </div>
}

export default CalendarMonths;
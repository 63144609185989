import React, { useState } from 'react';
import ReactDOM from "react-dom";

const tooltipRootID = 'tooltip-root';
const tooltipRoot = document.createElement('div');
tooltipRoot.id = tooltipRootID;
const getTooltipRootElement = () => document.getElementById(tooltipRootID);
if(!getTooltipRootElement()) document.body.prepend(tooltipRoot);

const PortalTooltip = ({title, rect, className=''}) => {
    const tooltipRootElement = getTooltipRootElement();

    const renderStyle = () => {
        const winWidth = window.innerWidth;
        const winHeight = window.innerHeight;
        const initialLeft = rect?.left;
        const initialTop = (rect?.top + rect?.height);
        const xPos = initialLeft >= (winWidth - 150) ? { right: (winWidth-rect?.left-rect?.width) + 'px' } : { left: rect?.left + 'px' };
        const yPos = initialTop > 100 ? { bottom: (winHeight-(rect?.top-10)) + 'px' } : { top: (rect?.top + rect?.height + 10) + 'px' };
        const arrowPosX = xPos?.left ? { left: (rect?.width/2) + 'px' } : { right: (rect?.width/2) + 'px' };
        const arrowPosY = yPos?.top ? 'bottom' : 'top';
        return { tooltip: { ...yPos, ...xPos }, arrowX: { ...arrowPosX }, arrowY: arrowPosY };
    }

    const renderTooltip = () => {
        return rect ? <div className={`tooltip-wrap arrow-${renderStyle()?.arrowY} ${className}`} style={renderStyle()?.tooltip}>
            <span className='tooltip-arrow' style={renderStyle()?.arrowX} />
            {title}
        </div> : null;
    }

    return ReactDOM.createPortal(
        renderTooltip(),
        tooltipRootElement
    );
};

const Tooltip = ({title, children, className='', disableChild=true, ...restProps}) => {
    const [rect, setRect] = useState(null);
    const handleMouseOver = e => {
        const { target } = e;
        const rect = target.getBoundingClientRect();
        setRect(rect);
    }

    const tooltipProps = { title, rect, className };

    return <div {...restProps} className={`tooltip-source ${disableChild?'disable-child':''} ${className}`} onMouseOver={handleMouseOver} onMouseOut={()=>setRect(null)}>
        {children}
        <PortalTooltip {...tooltipProps} />
    </div>;
};

export default Tooltip;
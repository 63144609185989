import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchHandler } from "../fetchHandler";

export const fetchProjects = createAsyncThunk("fetchProjects", async () => {
    const response = await fetchHandler({
        url: `/projects/get-list`,
        method: "GET"
    });
    return response?.data;
});

export const projectsSlice = createSlice({
    name: "projects",
    initialState: {
        loading: true,
        projects: [],
    },
    extraReducers: {
        [fetchProjects.pending]: (state) => {
            state.projects = [];
            state.loading = true;
        },
        [fetchProjects.fulfilled]: (state, action) => {
            state.projects = action.payload?.result ? action.payload?.result : [];
            state.loading = false;
        },
    },
});

export default projectsSlice.reducer;

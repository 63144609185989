export const dayLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const monthLabels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const pickerTypes = {
    second: 'second',
    minute: 'minute',
    hour: 'hour',
    date: 'date',
    month: 'month',
    year: 'year',
    datetime: 'datetime'
}
export const displayTypes = {
    inline: 'inline',
    dropdown: 'dropdown',
    popup: 'popup'
}

export const pad = (n, width, z = '0') => {
    return String(n).padStart(width, z);
}

export const handleCurrentDateClassName = event => {
    const eventDateFull = event.dateFull;
    const dateFull = new Date();
    const date = dateFull.getDate();
    const month = dateFull.getMonth();
    const year = dateFull.getFullYear();
    
    const eventDate = eventDateFull.getDate();
    const eventMonth = eventDateFull.getMonth();
    const eventYear = eventDateFull.getFullYear();

    if(date === eventDate && month === eventMonth && year === eventYear) {
        return 'current-date';
    } else {
        return '';
    }
}

export const renderTime = time => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const setAMPM = hours > 11 ? 'pm' : 'am';
    return hours > 12 ? `${pad(hours-12,2)}:${pad(minutes,2)}${setAMPM}` : `${pad(hours,2)}:${pad(minutes,2)}${setAMPM}`
}
export const renderDuration = duration => {
    const [hours, minutes] = duration;
    const renderHours = hours ? `${pad(hours,2)} hour${hours > 1 ? 's' : ''}` : null;
    const renderMinutes = minutes ? `${pad(minutes,2)} minute${minutes > 1 ? 's' : ''}` : null;
    return `${renderHours ? `${renderHours}` : ''}${renderMinutes ? ` and ${renderMinutes}` : ''}`;
}

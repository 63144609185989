import React from 'react';
import { monthLabels, dayLabels } from './--constants';

import CalendarDayLabels from "./calendar-day-labels";

const CalendarDates = ({view, value, dt, dateList, onSelect, renderContent=()=>null}) => {
    const checkCurrentDate = event => {
        const dt = new Date();
        return (event?.getDate() === dt.getDate() && event?.getMonth() === dt.getMonth()) ? true : false;
    }

    const checkPastDate = dt => {        
        const ndt1 = new Date();
        const ndt2 = new Date(ndt1.getFullYear(),ndt1.getMonth(),ndt1.getDate());
        return dt.getTime() < ndt2.getTime() ? true : false;
    }

    const renderDates = () => {
        return dateList.map((i,index) => {
            const ndt = i?.dateFull;
            const day = ndt.getDay();
            const date = ndt.getDate();
            const secondSaturday = day===6 && date > 7 && date < 15 ? 'sec-sat' : '';
            const forthSaturday = day===6 && date > 21 && date < 29 ? 'for-sat' : '';
            const month = monthLabels[ndt.getMonth()].substring(0,3);
            const isPastDate = checkPastDate(ndt);
            const monthType = ndt.getMonth() === dt.getMonth() ? 'this-month' : ndt.getMonth() < dt.getMonth() ? 'prev-month' : 'next-month';
            const isDateCrurrent = checkCurrentDate(ndt) ? 'this-date' : '';
            const isDateSelected = ndt.getFullYear() === value.getFullYear() && ndt.getMonth() === value.getMonth() && ndt.getDate() === value.getDate() ? 'selected' : '';
            const viewMonthLabel = index===0||date===1;
            return <div key={index} className={`dates-date ${monthType} ${dayLabels[day].toLowerCase().substring(0,3)} ${isDateCrurrent} ${isDateSelected} ${secondSaturday} ${forthSaturday} ${isPastDate?'past-date':''} ${viewMonthLabel?`month-${month.toLowerCase()}`:''}`} onClick={()=>onSelect(ndt)}>
                <div className='dates-label'>{date}</div>
                {viewMonthLabel?<div className='dates-month-label'>{month}</div>:null}
                {renderContent(ndt)}
            </div>
        })
    }

    return <>
        <CalendarDayLabels />
        <div className='calendar-dates'>
            {renderDates()}
        </div>
    </>
}

export default CalendarDates;
import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { getTimeFromMinutes } from '../../helper';
import { getEventPath } from '../../utils';
import "./index.scss";

const PortalTimePickerAnalog = ({children}) => {
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        el.id = 'timepickeranalog-root';
        let timepickeranalogRoot = document.querySelector('#timepickeranalog-root');
        !timepickeranalogRoot && document.body.appendChild(el);
        return () => {
            document.body.removeChild(el);
        }
    }, [el]);

    return ReactDOM.createPortal(
        children,
        el
    );
};

const TimePickerAnalog = ({value=null, onChange=()=>{}, tabIndex=1}) => {
    const ref = useRef();
    const [open, setOpen] = useState();    
    const [timePickerAnalogRect, setTimePickerAnalogRect] = useState(null);

    const openTPA = e => {
        setTimePickerAnalogRect(e.target.getBoundingClientRect());
        setOpen(true);
    }

    useEffect(() => {
        const listener = e => {
            const path = getEventPath(e);
            const timepickeranalogRoot = document.querySelector('#timepickeranalog-root');
            const innerClick = path.includes(ref?.current) || path.includes(timepickeranalogRoot);
            if(!innerClick && open) {
                setOpen(false);
            }
        }
        window.addEventListener('click', listener);
        return () => {
            window.removeEventListener('click', listener);
        }
    });

    useEffect(() => {
        if(open) {
            setTimeout(() => {
                const timepickeranalogRoot = document.querySelector('#timepickeranalog-root');
                const hoursField = timepickeranalogRoot.querySelector('.field-hours');
                hoursField?.focus();
            }, 0);
        }
    }, [open]);

    const time = getTimeFromMinutes(value);
    const [hours, setHours] = useState(time.hours);
    const [minutes, setMinutes] = useState(time.minutes);
    const [am, setAM] = useState(time.ampm==='am');

    const minutesToDegree = (360/60) * time.minutes;
    const hoursToDegree = ((360/12) * time.hours) + (minutesToDegree/60);

    const handleOnChange = (h=null, m=null, a=null) => {
        const hoursValue = h ? h : hours;
        const minutesValue = m ? m : minutes;
        const amValue = a !== null ? a : am;
        const finalMinutes = amValue ? 
        ((hoursValue===12 ? 0 : (hoursValue * 60)) + minutesValue) : 
        (hoursValue === 12 ? 12*60 : ((Number(hoursValue) + 12) * 60)) + minutesValue;

        onChange(finalMinutes);
    }

    const setHoursFn = e => {
        const value = e?.target?.value;
        setHours(value.slice(-2));
    }

    const updateHours = () => {
        const value = Number(hours)%12;
        setHours(value === 0 ? 12 : value);
        handleOnChange(value);
    }

    const setMinutesFn = e => {
        const value = e?.target?.value;
        setMinutes(value.slice(-2));
    }

    const updateMinutes = () => {
        const value = Number(minutes)%60;
        setMinutes(value);
        handleOnChange(null, value);
    }

    const setAMFn = () => {
        setAM(!am);
        handleOnChange(null, null, !am);
    }

    return <div className='analog-clock-wrap' ref={ref}>
        <button type='button' tabIndex={tabIndex} className='analog-clock' title={getTimeFromMinutes(value).string} onFocus={openTPA} onClick={openTPA}>
            <div className='hours' style={{transform: `rotate(${hoursToDegree}deg)`}} />
            <div className='minutes' style={{transform: `rotate(${minutesToDegree}deg)`}} />
        </button>
        {open?<PortalTimePickerAnalog>
            <div className='analog-clock-picker' style={{
                top: (timePickerAnalogRect?.top + timePickerAnalogRect?.height) + 'px', 
                left: (timePickerAnalogRect?.left - 64) + 'px'
            }}>
                <div className='fields'>
                    <input type="number" value={hours} onChange={setHoursFn} onBlur={updateHours} className='field field-hours' />
                    <input type="number" value={minutes} onChange={setMinutesFn} onBlur={updateMinutes} className='field field-minutes' />
                    <div onClick={setAMFn} className='field field-ampm'>{am?'AM':'PM'}</div>
                </div>
            </div>
        </PortalTimePickerAnalog>:null}
    </div>
};

export default TimePickerAnalog;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchHandler } from '../fetchHandler';

export const fetchNotes = createAsyncThunk('fetchNotes', async () => {
    const response = await fetchHandler({
        url: `/notes/get-list`,
        method: 'GET'
    });
    return response?.data;
});

export const addNote = createAsyncThunk('addNote', async (body) => {
    const response = await fetchHandler({
        url: `/notes/add-edit`,
        method: 'POST',
        body
    });
    return response?.data;
});

export const removeNote = createAsyncThunk('removeNote', async (body) => {
    const response = await fetchHandler({
        url: `/notes/remove`,
        method: 'DELETE',
        body
    });
    return response?.data;
});

export const notesSlice = createSlice({
    name: 'notes',
    initialState: {
        notes: []
    },
    extraReducers: {
        [fetchNotes.fulfilled]: (state, action) => {
            state.notes = action?.payload?.result;
        }
    }
});

export default notesSlice.reducer

import ReactQuill, { Quill } from 'react-quill';
import { useMemo, useRef } from "react";
import "react-quill/dist/quill.snow.css";

const Inline = Quill.import('blots/inline');

class PasswordBlot extends Inline {
    static create(value) {
        let node = super.create();
        if (value) {
            node.classList.add('password');
        } else {
            node.classList.remove('password');
        }
        return node;
    }

    static formats(node) {
        return node.classList.contains('password');
    }

    format(name, value) {
        if (name === 'password') {
            if (value) {
                this.domNode.classList.add('password');
            } else {
                this.domNode.classList.remove('password');
            }
        } else {
            super.format(name, value);
        }
    }
}

PasswordBlot.blotName = 'password';
PasswordBlot.tagName = 'span';
Quill.register(PasswordBlot);

var icons = ReactQuill.Quill.import("ui/icons");

icons["code-block"] = `<svg height="24px" viewBox="0 -960 960 960" width="24px">
<path class="ql-fill" d="M320-240 80-480l240-240 57 57-184 184 183 183-56 56Zm320 0-57-57 184-184-183-183 56-56 240 240-240 240Z"/>
</svg>`;
icons["undo"] = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
</svg>`;
icons["redo"] = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
</svg>`;
icons["cut"] = `<svg height="24px" viewBox="0 -960 960 960" width="24px">
<path class="ql-fill" d="M760-120 480-400l-94 94q8 15 11 32t3 34q0 66-47 113T240-80q-66 0-113-47T80-240q0-66 47-113t113-47q17 0 34 3t32 11l94-94-94-94q-15 8-32 11t-34 3q-66 0-113-47T80-720q0-66 47-113t113-47q66 0 113 47t47 113q0 17-3 34t-11 32l494 494v40H760ZM600-520l-80-80 240-240h120v40L600-520ZM240-640q33 0 56.5-23.5T320-720q0-33-23.5-56.5T240-800q-33 0-56.5 23.5T160-720q0 33 23.5 56.5T240-640Zm240 180q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6ZM240-160q33 0 56.5-23.5T320-240q0-33-23.5-56.5T240-320q-33 0-56.5 23.5T160-240q0 33 23.5 56.5T240-160Z"/>
</svg>`;
icons["copy"] = `<svg height="24px" viewBox="0 -960 960 960" width="24px">
<path class="ql-fill" d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/>
</svg>`;
icons["paste"] = `<svg height="24px" viewBox="0 -960 960 960" width="24px">
<path class="ql-fill" d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594-840h166q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560h-80v120H280v-120h-80v560Zm280-560q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Z"/>
</svg>`;
icons["password"] = `<svg height="24px" viewBox="0 -960 960 960" width="24px">
<path class="ql-fill" d="M80-200v-80h800v80H80Zm46-242-52-30 34-60H40v-60h68l-34-58 52-30 34 58 34-58 52 30-34 58h68v60h-68l34 60-52 30-34-60-34 60Zm320 0-52-30 34-60h-68v-60h68l-34-58 52-30 34 58 34-58 52 30-34 58h68v60h-68l34 60-52 30-34-60-34 60Zm320 0-52-30 34-60h-68v-60h68l-34-58 52-30 34 58 34-58 52 30-34 58h68v60h-68l34 60-52 30-34-60-34 60Z"/>
</svg>`;

const Editor = ({name, value, onChange=()=>{}}) => {
    const quill = useRef();
    
    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [2, 3, 4, false] }],
                    ["bold", "italic", "underline"],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "password"],
                    [{'code-block': true}],
                    ["undo", "redo"],
                    ["cut", "copy", "paste"],
                    ["clean"],
                ],
                handlers: {
                    'code-block': () => {
                        const quillEditor = quill.current.getEditor();
                        const cursorPosition = quillEditor.getSelection().index;
                        quillEditor.insertText(cursorPosition, '\n');
                        quillEditor.formatLine(cursorPosition + 1, 1, 'code-block', true);
                        quillEditor.insertText(cursorPosition + 1, 'Your code here');
                    },
                    undo: () => {
                        const quillEditor = quill.current.getEditor();
                        quillEditor.history.undo();
                    },
                    redo: () => {
                        const quillEditor = quill.current.getEditor();
                        quillEditor.history.redo();
                    },
                    cut: async () => {
                        const quillEditor = quill.current.getEditor();
                        const range = quillEditor.getSelection();
                        if (range) {
                        const text = quillEditor.getText(range.index, range.length);
                        await navigator.clipboard.writeText(text);
                        quillEditor.deleteText(range.index, range.length);
                        }
                    },
                    copy: async () => {
                        const quillEditor = quill.current.getEditor();
                        const range = quillEditor.getSelection();
                        if (range) {
                        const text = quillEditor.getText(range.index, range.length);
                        await navigator.clipboard.writeText(text);
                        }
                    },
                    paste: async () => {
                        const text = await navigator.clipboard.readText();
                        const quillEditor = quill.current.getEditor();
                        const range = quillEditor.getSelection();
                        quillEditor.insertText(range.index, text);
                    },
                    password: () => {
                        const quillEditor = quill.current.getEditor();
                        const range = quillEditor.getSelection();
                        if (range) {
                            if (range.length === 0) {
                                return;
                            }
                            const format = quillEditor.getFormat(range);
                            if (format['password']) {
                                quillEditor.formatText(range.index, range.length, 'password', false);
                            } else {
                                quillEditor.formatText(range.index, range.length, 'password', true);
                            }
                        }
                    }
                },
                
            },
            clipboard: {
                matchVisual: false,
            },
        }),
        []
    );
    
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        'code-block',
        "clean",
        "password"
    ];

    const quillValue = value?.startsWith('<') ? value : `<p>${value}</p>`;
    return <div className={`form-control-box type-quill`}>
        <ReactQuill ref={(el) => (quill.current = el)} theme="snow" name={name} value={quillValue} onChange={e=>onChange(e, 'quill')} formats={formats} modules={modules} />
    </div>;
};

export default Editor;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchHandler } from "../fetchHandler";

export const fetchDues = createAsyncThunk("fetchDues", async () => {
    const response = await fetchHandler({
        url: `/dues/get-dues`,
        method: "GET"
    });
    return response;
});

export const addDue = createAsyncThunk("addDue", async (body) => {
    const response = await fetchHandler({
        url: `/dues/add-edit`,
        method: "POST",
        body
    });
    return response;
});

export const removeDue = createAsyncThunk("removeDue", async (body) => {
    const response = await fetchHandler({
        url: `/dues/remove`,
        method: "DELETE",
        body
    });
    return response;
});

export const duesSlice = createSlice({
    name: "dues",
    initialState: {
        dues: {},
    },
    extraReducers: {
        [fetchDues.fulfilled]: (state, action) => {
            state.dues = action.payload?.data?.result;
        },
    },
});

export default duesSlice.reducer;

import React, { useEffect, useState } from 'react';
import './index.scss';

const Table = ({columns=[], data=[], className=""}) => {
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortColumn, setSortColumn] = useState(columns?.find(item=>item?.baseColumn));

    const [list, setList] = useState([]);

    useEffect(() => {
        if(sortOrder && sortColumn) {
            const list = data?.sort((a,b) => {
                if(sortOrder==='asc') {
                    return a[sortColumn?.accessor] < b[sortColumn?.accessor] ? -1 : a[sortColumn?.accessor] > b[sortColumn?.accessor] ? 1 : 0;
                } else {
                    return a[sortColumn?.accessor] < b[sortColumn?.accessor] ? 1 : a[sortColumn?.accessor] > b[sortColumn?.accessor] ? -1 : 0;
                }
            });
            setList([...list]);
        }
    }, [data, sortOrder, sortColumn]);

    const sortColumnFn = index => {
        const column = columns[index];
        if(sortColumn?.accessor !== column?.accessor) {
            setSortColumn(column);
            setSortOrder('asc');
        } else {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        }
    }

    const renderHeader = () => {
        return <>
            <colgroup>
                {columns.map((item, index) => <col key={index} className={`col-${index+1} col-${item?.label.replace(/\s/g, '-').toLowerCase()}`} />)}
            </colgroup>
            <thead>
                <tr>
                    {columns.map((item, index) => <th key={index} className='text-left'>
                        <div className={`th-container ${item?.sortable ? 'sortable' : ''}`} onClick={() => item?.sortable ? sortColumnFn(index) : false}>
                            {list?.length && item?.sortable ? <span className='th-sort'>
                                <i className={`icon-${sortColumn?.accessor!==item?.accessor ? 'sortable' : sortOrder==='asc'?'sort-asc':'sort-desc'}`} />
                            </span> : null}
                            <span className='th-label'>{item?.label}</span>
                        </div>
                    </th>)}
                </tr>
            </thead>
        </>
    }

    const renderNoData = () => {
        const colCount = columns?.length;
        if(list?.length) return null;
        return <tr><td colSpan={colCount} className="text-center">No Data</td></tr>
    }
    
    const renderBody = () => {
        return <tbody>
            {renderNoData()}
            {list?.map((item, index) => <tr key={index}>
                {columns?.map((column, columnIndex) => <td key={columnIndex} data-label={column?.label}>
                    {column?.render ? column?.render(item, index) : item[column?.accessor]}
                </td>)}
            </tr>)}
        </tbody>
    }

    return (
        <div className={`table-wrap ${className}`}>
            <table>
                {renderHeader()}
                {renderBody()}
            </table>
        </div>
    );
};

export default Table;

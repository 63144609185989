import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AES from "crypto-js/aes";

import { fetchHandler } from "../fetchHandler";

const loginOrVerifyFn = async (login = true, username, password) => {
    const getNonce = await fetchHandler({
        url: "/auth/get-nonce",
        method: "GET",
        secure: false,
    });
    const cipher = AES.encrypt(password, getNonce?.data?.nonce);
    const body = {
        username,
        password: cipher.toString(),
        nonce: getNonce?.data?.nonce,
        tag: getNonce?.data?.tag,
    };
    const response = await fetchHandler({
        url: login ? "/auth/login" : "/auth/verify",
        method: "POST",
        body,
        secure: login ? false : true,
    });
    return { ...response?.data };
};

export const login = createAsyncThunk(
    "login",
    async ({ username, password }) => {
        return loginOrVerifyFn(true, username, password);
    }
);

export const verify = createAsyncThunk(
    "verify",
    async ({ username, password }) => {
        return loginOrVerifyFn(false, username, password);
    }
);

export const verifyToken = createAsyncThunk("verify-token", async () => {
    const response = await fetchHandler({
        url: "/auth/verify-token",
        method: "GET",
    });
    return response?.data;
});

export const forgotPassword = createAsyncThunk(
    "forgotPassword",
    async (body) => {
        const response = await fetchHandler({
            url: "/users/forgot",
            method: "POST",
            body,
            secure: false,
        });
        return response?.data;
    }
);

export const resetPassword = createAsyncThunk(
    "resetPassword",
    async ({ password, resetId }) => {
        const getNonce = await fetchHandler({
            url: "/auth/get-nonce",
            method: "GET",
            secure: false,
        });
        const cipher = AES.encrypt(password, getNonce?.data?.nonce);
        const body = {
            resetId,
            password: cipher.toString(),
            nonce: getNonce?.data?.nonce,
            tag: getNonce?.data?.tag,
        };
        const response = await fetchHandler({
            url: "/users/reset",
            method: "POST",
            body,
            secure: false,
        });
        return response?.data;
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        userDetails: null,
        jwt: null
    },
    reducers: {
        logOut: (state) => {
            state.userDetails = null;
            state.jwt = null;
        },
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.userDetails = action?.payload?.data;
            state.jwt = action?.payload?.jwt;
        },
    },
});

export const { logOut } = authSlice.actions;

export default authSlice.reducer;

import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from "react-dom";
import FormField from '../form-field';
import { getEventPath } from '../../utils';
import "./index.scss";

const PortalSelectBox = ({children}) => {
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        el.id = 'select-box-root';
        document.body.appendChild(el);
        return () => {
            document.body.removeChild(el);
        }
    }, [el]);

    return ReactDOM.createPortal(
        children,
        el
    );
};

const SelectBox = ({className="", value='', label="", options=[], onChange=()=>{}, onCreate=()=>{}, searchable=false}) => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState(null);
    const [selectBoxRect, setSelectBoxRect] = useState(null);
    const [list, setList] = useState([]);
    const [showCreateBtn, setShowCreateBtn] = useState(false);

    const ref = useRef();

    useEffect(() => {
        const filtered = options.filter(i => i?.label?.toLowerCase().includes(search.toLowerCase()));
        setList(filtered);
        setShowCreateBtn(search && search !== '' && filtered.length === 0);
    }, [search, options]);

    useEffect(() => {
        const listener = e => {
            const path = getEventPath(e);
            const selectBoxRoot = document.querySelector('#select-box-root');
            const innerClick = path.includes(ref?.current) || path.includes(selectBoxRoot);
            if(!innerClick) {
                setOpen(false);
            }
        }
        window.addEventListener('click', listener);
        return () => {
            window.removeEventListener('click', listener);
        }
    })

    useEffect(() => {
        setSelected(list.find(i=>i?.value===value));
    }, [value, list]);

    const renderSelectBoxList = () => {
        return <div className='select-box-list'>
            {list.map((i,index)=><div key={index} className='list-item' onClick={()=>onSelect(i)}>{i.label}</div>)}
        </div>
    }
    const toggleSelectBox = (e) => {
        setSelectBoxRect(e.target.getBoundingClientRect());
        setOpen(!open);
    }
    const onSelect = e => {
        onChange(e);
        setOpen(!open);
    }
    const handleCreateType = () => {
        onCreate(search);
    }
    return (
        <div className={`select-box-wrap form-group ${className} ${open?'open':''}`} ref={ref}>
            <div className='select-box-toggle form-field' onClick={toggleSelectBox}>
                <div className='toggle-label'>{label}</div>
                <div className='toggle-value form-control'>{selected?.label}</div>
                <i className={`icon-chevron-${open ?'up':'down'}`} />
            </div>
            {open?<PortalSelectBox open={open} onClose={toggleSelectBox}>
                <div className="select-box-list-wrap" style={{
                        width: selectBoxRect?.width + 'px', 
                        top: (selectBoxRect?.top+selectBoxRect?.height) + 'px', 
                        left: selectBoxRect?.left + 'px'
                }}>
                    {searchable?<div className='select-box-search'>
                        <FormField value={search} onChange={e=>setSearch(e)} placeholder="Search" icon={showCreateBtn?null:<i className='icon-search' />} />
                        {showCreateBtn?<button className='btn btn-primary btn-cell btn-sm create-event-type-button' onClick={handleCreateType}><i className='icon-plus' /></button>:null}
                    </div>:null}
                    {renderSelectBoxList()}
                </div>
            </PortalSelectBox>:null}
        </div>
    );
};

export default SelectBox;
import React, { useEffect, useRef, useState } from 'react';
import { prePad } from '../../helper';
import { getEventPath } from '../../utils';
import "./index.scss";

const TimePicker = ({classes="", value=0, onChange=()=>{}, step=5, icon=null}) => {
    const [am, setAm] = useState(Math.floor(value/60)>11?false:true);
    const [hours, setHours] = useState(value===0 ? 12 : Math.floor(value/60)%12);
    const [minutes, setMinutes] = useState(value%60);

    const [openHours, setOpenHours] = useState(false);
    const [openMinutes, setOpenMinutes] = useState(false);

    const hoursRef = useRef();
    const minutesRef = useRef();

    useEffect(() => {
        const val = ((am&&hours===12?0:hours)*60)+minutes+(am?0:(!am&&hours===12)?0:(12*60));
        onChange(val);
    }, [hours, minutes, am, onChange]);

    useEffect(() => {
        const listener = e => {
            const path = getEventPath(e);
            if(!path.includes(hoursRef?.current)) {
                setOpenHours(false);
            }
            if(!path.includes(minutesRef?.current)) {
                setOpenMinutes(false);
            }
        }
        window.addEventListener('click', listener);
        return () => {
            window.removeEventListener('click', listener);
        }
    })

    const renderHours = () => {
        const arr = [];
        for(let i=0; i<12; i++) {
            const value = i+1;
            arr.push(<div key={i} className={`time-item hour ${hours===value?'active':''}`} onClick={()=>setHours(value)}>{value}</div>)
        }
        return <div className='time-items'>{arr}</div>;
    }

    const renderMinutes = () => {
        const arr = [];
        for(let i=0; i<60; i++) {
            const value = i;
            i%step === 0 && arr.push(<div key={i} className={`time-item minutes ${minutes===value?'active':''}`} onClick={()=>setMinutes(value)}>{value}</div>)
        }
        return <div className='time-items'>{arr}</div>;
    }

    return (
        <label className={`time-picker form-group ${classes}`}>
            <div className="form-label">Time From</div>
            <div className="form-field">
                <div className='d-flex'>
                    <div className='form-control time-hours'>
                        <div className='time-value' ref={hoursRef} onClick={()=>setOpenHours(true)}>{prePad(hours)}</div>
                        {openHours && renderHours()}
                    </div>
                    <div className='form-control time-minutes'>
                        <div className='time-value' ref={minutesRef} onClick={()=>setOpenMinutes(true)}>{prePad(minutes)}</div>
                        {openMinutes && renderMinutes()}
                    </div>
                    <div className='form-control time-am-pm' onClick={()=>setAm(!am)}>{am?'AM':'PM'}</div>
                </div>
                <div className="icon scale-75">{icon}</div>
            </div>
        </label>
    );
};

export default TimePicker;
import React, { useState } from 'react';
import { dayLabels } from '../../constants';

const DaySelector = ({value=[dayLabels[1]], onSelect=()=>{}}) => {
    const [selected, setSelected] = useState(value);
    const handleSelection = day => {
        const newSelected = selected.includes(day) && selected.length > 1 ? selected.filter(i=>i!==day) : !selected.includes(day) ? [...selected, day] : [...selected];
        setSelected(newSelected);
        onSelect(newSelected);
    }
    return (
        <div className='day-selector'>
            {dayLabels.map((day)=><div key={day} className={`day ${selected.includes(day) ? 'selected' : ''}`} onClick={()=>handleSelection(day)}>{day.substring(0,1)}</div>)}
        </div>
    );
};

export default DaySelector;
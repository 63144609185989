import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToggleButton, Tooltip } from '../ui-components';
import { themeLight, themeDark } from '../constants';
import { setTheme } from '../store';

const UtilToggleTheme = () => {
    const reduxDispatch = useDispatch();
	const { theme } = useSelector(state=>state?.app);

    const onToggleTheme = e => {
        reduxDispatch(setTheme(e?themeDark:themeLight));
    }

    return <Tooltip title="Toggle theme" disableChild={false}>
        <ToggleButton className='toggle-theme' on={theme===themeDark} onClick={onToggleTheme} />
    </Tooltip>;
};

export default UtilToggleTheme;
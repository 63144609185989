import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchHandler } from '../fetchHandler';

export const fetchMonthlyExpenses = createAsyncThunk('fetchMonthlyExpenses', async ({year, month}) => {
    const response = await fetchHandler({
        url: `/monthly-expenses/get-list?y=${year}&m=${month}`,
        method: 'GET'
    });
    return response?.data;
});

export const addMonthlyExpense = createAsyncThunk('addMonthlyExpense', async (body) => {
    const response = await fetchHandler({
        url: `/monthly-expenses/add-edit`,
        method: 'POST',
        body
    });
    return response?.data;
});

export const updateMonthlyExpenseStatus = createAsyncThunk('updateMonthlyExpenseStatus', async (body) => {
    const response = await fetchHandler({
        url: `/monthly-expenses/update-status`,
        method: 'POST',
        body
    });
    return response?.data;
});

export const deleteMonthlyExpense = createAsyncThunk('deleteMonthlyExpense', async (body) => {
    const response = await fetchHandler({
        url: `/monthly-expenses/remove`,
        method: 'DELETE',
        body
    });
    return response?.data;
});

export const monthlyExpensesSlice = createSlice({
    name: 'monthlyExpenses',
    initialState: {
        monthlyExpenses: []
    },
    extraReducers: {
        [fetchMonthlyExpenses.pending]: (state) => {
            state.monthlyExpenses = []
        },
        [fetchMonthlyExpenses.fulfilled]: (state, action) => {
            state.monthlyExpenses = action.payload?.result;
        }
    }
});

export default monthlyExpensesSlice.reducer

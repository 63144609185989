import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchHandler } from '../fetchHandler';

export const fetchExpenseAccounts = createAsyncThunk('fetchExpenseAccounts', async () => {
    const response = await fetchHandler({
        url: `/expense-accounts`,
        method: 'GET'
    });
    return response?.data;
});

export const addExpenseAccount = createAsyncThunk('addExpenseAccount', async (body) => {
    const response = await fetchHandler({
        url: `/expense-accounts`,
        method: 'POST',
        body
    });
    return response?.data;
});

export const expenseAccountsSlice = createSlice({
    name: 'expenseAccounts',
    initialState: {
        expenseAccounts: []
    },
    extraReducers: {
        [fetchExpenseAccounts.pending]: (state) => {
            state.expenseAccounts = []
        },
        [fetchExpenseAccounts.fulfilled]: (state, action) => {
            state.expenseAccounts = action.payload?.result;
        }
    }
});

export default expenseAccountsSlice.reducer

import React from 'react';
import './index.scss';

const ComponentSideNav = ({className="", tabs=[], activeTab, setActiveTab=()=>{}, open=false, setOpen=()=>{}}) => {
    const onTabChange = tab => {
        setActiveTab(tab);
        setOpen(false);
    }
    return <>
        <div className={`side-nav-overlay ${open?'open':''}`} onClick={()=>setOpen(false)} />
        <div className={`side-nav-wrap ${open?'open':''} ${className}`}>
            <ul className='side-nav'>
                {tabs.map(tab => <li key={tab?.label} className={`${tab?.label===activeTab?'active':''}`} onClick={()=>onTabChange(tab?.label)}>
                    {tab?.label}
                </li>)}
            </ul>
        </div>
    </>;
};

export default ComponentSideNav;
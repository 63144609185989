import React, { useCallback, useEffect, useRef, useState } from 'react';

const TimeIndicator = () => {
    const ref = useRef();
    useEffect(() => {
        ref?.current?.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        });
    }, []);
    const calculatePercentage = useCallback(() => {
        const dt = new Date();
        const hours = (dt.getHours()*60)*60;
        const minutes = dt.getMinutes()*60;
        const percentage = ((hours+minutes)/86400)*100;
        return percentage;
    },[]);
    const [percentage, setPercentage] = useState(calculatePercentage());
    useEffect(() => {
        const interval = setInterval(() => {
            setPercentage(calculatePercentage());
        }, 60000);
        return () => {
            clearInterval(interval);
        }
    });
    return (
        <div className='time-indicator' ref={ref} style={{top: percentage+'%'}} />
    );
};

export default TimeIndicator;
import React, { useEffect, useState } from "react";

const CalendarYears = ({dt= null, onSelect = () => false}) => {
    const [year, setYear] = useState(2022);
    
    useEffect(() => {
        dt && setYear(dt.getFullYear());
    }, [dt]);

    const renderYearLabels = () => {
        const floorYear = year-(year%23);
        const yearLabels = [...new Array(23)].map((i,index)=>(floorYear+index));
        return yearLabels.map(item => {
            const isYearCurrent = new Date().getFullYear() === item ? 'this-year' : '';
            const isYearSelected = dt.getFullYear() === item ? 'selected' : '';
            return <div key={item} className={`year ${isYearCurrent} ${isYearSelected}`} onClick={()=>onSelect(item)}>
                <div className="year-label">{item}</div>
            </div>;
        });
    }

    return <div className="calendar-years">
        <div className="years-prev-button" onClick={()=>setYear(year-23)}><div className="button-label" /></div>
        {renderYearLabels()}
        <div className="years-next-button" onClick={()=>setYear(year+23)}><div className="button-label" /></div>
    </div>
}

export default CalendarYears;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchHandler } from '../fetchHandler';

export const fetchPayslips = createAsyncThunk('fetchPayslips', async () => {
    const response = await fetchHandler({
        url: `/payslips/get-list`,
        method: 'GET'
    });
    return response?.data;
});

export const uploadPayslips = createAsyncThunk('uploadPayslips', async (formData) => {
    const response = await fetchHandler({
        type: 'multipart/form-data',
        url: `/payslips/uploads`,
        method: 'POST',
        body: formData
    });
    return response?.data;
});

export const addPayslips = createAsyncThunk('addPayslips', async (body) => {
    const response = await fetchHandler({
        url: `/payslips/add-edit`,
        method: 'POST',
        body
    });
    return response?.data;
});

export const cancelAddPayslips = createAsyncThunk('cancelAddPayslips', async (body) => {
    const response = await fetchHandler({
        url: `/payslips/cancel`,
        method: 'POST',
        body
    });
    return response?.data;
});

export const payslipsSlice = createSlice({
    name: 'payslips',
    initialState: {
        payslips: {}
    },
    extraReducers: {
        [fetchPayslips.fulfilled]: (state, action) => {
            state.payslips = action.payload?.result;
        }
    }
});

export default payslipsSlice.reducer

import { createSlice } from '@reduxjs/toolkit';

export const widgetsSlice = createSlice({
    name: 'widgets',
    initialState: {
        widgetPdf: null
    },
    reducers: {
        setWidgetPdf: (state, action) => {
            state.widgetPdf = action?.payload;
        }
    }
});

export const { setWidgetPdf } = widgetsSlice.actions;

export default widgetsSlice.reducer
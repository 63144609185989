import React from "react";
import { useSelector } from "react-redux";

import { ComponentHeader, ComponentNav } from "../components";

const PageLogin = React.lazy(() => import("./sign-in"));
const PageForgotPassword = React.lazy(() => import("./forgot-password"));
const PageResetPassword = React.lazy(() => import("./reset-password"));
const PageDashboard = React.lazy(() => import("./dashboard"));
const PageTransactions = React.lazy(() => import("./transactions"));
const PageTransactionsHistory = React.lazy(() =>
    import("./transactions-history")
);
const PageSettings = React.lazy(() => import("./settings"));
const PageEvents = React.lazy(() => import("./events"));
const PageDues = React.lazy(() => import("./dues"));
const PagePayslips = React.lazy(() => import("./payslips"));
const PageNotes = React.lazy(() => import("./notes"));
const PageAncestry = React.lazy(() => import("./ancestry"));
const PageXLSXExport = React.lazy(() => import("./xlsx-export"));
const PagePhotos = React.lazy(() => import("./photos"));
const PageFinancialCalculator = React.lazy(() =>
    import("./financial-calculator")
);
const PageRnD = React.lazy(() => import("./rnd"));
const PageTodo = React.lazy(() => import("./todo"));
const PageContacts = React.lazy(() => import("./contacts"));
const PageSheets = React.lazy(() => import("./excel"));

const PageLoader = ({ className = "", children, afterBody = () => {} }) => {
    const { dashboardNavOpen } = useSelector((state) => state?.dashboard);

    return (
        <div
            className={`page ${className} ${
                dashboardNavOpen ? "nav-open" : ""
            }`}
        >
            <ComponentHeader />
            <div className="page-body">
                <ComponentNav />
                <div className="page-content">{children}</div>
                {afterBody()}
            </div>
        </div>
    );
};

export {
    PageLoader,
    PageLogin,
    PageForgotPassword,
    PageResetPassword,
    PageDashboard,
    PageTransactions,
    PageTransactionsHistory,
    PageSettings,
    PageEvents,
    PageDues,
    PagePayslips,
    PageNotes,
    PageAncestry,
    PageXLSXExport,
    PagePhotos,
    PageFinancialCalculator,
    PageRnD,
    PageTodo,
    PageContacts,
    PageSheets
};

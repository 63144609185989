import React from 'react';
import "./index.scss";

const ToggleButton = ({ className='', on=false, onClick=()=>{} }) => {
    const onBtnClick = () => {
        onClick(!on);
    }
    return <button className={`button-toggle ${on ? 'on' : 'off'} ${className}`} type='button' onClick={onBtnClick}>
        <span className='button-toggle-dot' />
    </button>;
};

export default ToggleButton;
import React, { useEffect } from 'react';
import "./index.scss";

function renderTags(csm, onTagClick=()=>{}) {
    const stringContent = csm.replace(/\[([^\]]+)\]/g, (_, content) => {
        const contactUserId = content?.split('|')[0];
        const removeDigitsAndPipe = content.replace(/[\d+|]/g, '');
        return `<a href="#" data-userid="${contactUserId}">@${removeDigitsAndPipe}</a>`;
    });
    const contentEditableProps = {
        contentEditable: true,
        suppressContentEditableWarning: true
    }
    const span = document?.createElement('span');
    span.innerHTML = stringContent;
    const newContent = [...span?.childNodes].map((node,index) => node?.nodeName==='A' ? 
        <span key={index} className='tag' {...contentEditableProps} data-userid={node?.dataset?.userid} onClick={()=>onTagClick(node?.dataset?.userid)}>{node?.innerHTML}</span> : 
        <span key={index} {...contentEditableProps}>{node?.textContent}</span>);
    return <>
        {newContent}
        <span {...contentEditableProps} />
    </>;
}

function getCaretPosition(editableDiv) {
    let caretPos = 0,
        sel,
        range,
        count;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel.rangeCount) {
            range = sel.getRangeAt(0);
            count = range?.endContainer?.length;
            if (range.commonAncestorContainer.parentNode === editableDiv) {
                caretPos = range.endOffset;
            }
        }
    } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        if (range.parentElement() === editableDiv) {
            let tempEl = document.createElement("span");
            editableDiv.insertBefore(tempEl, editableDiv.firstChild);
            let tempRange = range.duplicate();
            tempRange.moveToElementText(tempEl);
            tempRange.setEndPoint("EndToEnd", range);
            caretPos = tempRange.text.length;
            count = range?.length;
        }
    }
    return {caretPos, count};
}

function moveCaretToEnd(editableDiv) {
    let range,selection;
    if(document.createRange) {    
        range = document.createRange();//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(editableDiv);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection();//get the selection object (allows you to change selection)
        selection.removeAllRanges();//remove any selections already made
        selection.addRange(range);//make the range you have just created the visible selection
    } else if(document.selection) { 
        range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
        range.moveToElementText(editableDiv);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        range.select();//Select the range (make it the visible selection
    }
}

const TaggableTextarea = ({csm='', onCSMUpdate=()=>{}, onTagClick=()=>{}}) => {
    useEffect(() => {
        const listener = e => {
            e.preventDefault();
            const {target} = e;
            const {caretPos,count} = getCaretPosition(target);
            const previousElementSibling = target?.previousElementSibling;
            const nextElementSibling = target?.nextElementSibling;
            const isTag = target?.classList?.contains('tag');
            const isBackspace = e?.keyCode===8;// checking backspace key
            const isDelete = e?.keyCode===46;// checking delete key
            if(isTag && (isBackspace || isDelete)) {
                previousElementSibling?.focus();
                moveCaretToEnd(previousElementSibling);
                target?.remove();
            } else if(caretPos===0 && previousElementSibling) {
                previousElementSibling?.focus();
                moveCaretToEnd(previousElementSibling);
            } else if(caretPos===count && nextElementSibling) {
                nextElementSibling?.focus();
            }
            const csmEditorChildren = [...document?.getElementById('csm-editor').children];
            let newCSM = '';
            csmEditorChildren.forEach(span => {
                newCSM += span?.classList?.contains('tag') ? `[${span?.dataset?.userid}|${span?.innerText?.slice(1)}]` : span?.innerText
            });
            onCSMUpdate(newCSM);
        }
        window.addEventListener('keyup', listener);
        return () => {
            window?.removeEventListener('keyup', listener);
        }
    }, [onCSMUpdate]);
    return (
        <div className='taggable-wrapper'>
            <div className='taggable-container' id='csm-editor'>
                {csm && renderTags(csm, id=>onTagClick(id))}
            </div>
        </div>
    );
};

export default TaggableTextarea;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchHandler } from '../fetchHandler';

export const uploadFiles = createAsyncThunk('uploadFiles', async (formData) => {
    const response = await fetchHandler({
        type: 'multipart/form-data',
        url: '/image/upload',
        method: 'POST',
        body: formData
    });
    return response?.data;
});

export const amrUpload = createAsyncThunk('amrUpload', async (formData) => {
    const response = await fetchHandler({
        type: 'multipart/form-data',
        url: '/contacts/amr-upload',
        method: 'POST',
        body: formData
    });
    return response?.data;
});

export const getAllUploads = createAsyncThunk('getAllUploads', async () => {
    const response = await fetchHandler({
        url: '/uploads/get-list',
        method: 'GET'
    });
    return response?.data;
});

export const deleteUploadFile =  createAsyncThunk('deleteUploadFile', async (file) => {
    const response = await fetchHandler({
        url: '/uploads',
        method: 'DELETE',
        body: { file: `uploads/${file}` }
    });
    return response?.data;
});

export const uploadSlice = createSlice({
    name: 'upload',
    initialState: {
        files: [],
        uploads: {}
    },
    extraReducers: {
        [getAllUploads.fulfilled]: (state, action) => {
            state.uploads = {linked: action.payload?.linked, nonLinked: action.payload?.nonLinked};
        }
    }
});

export default uploadSlice.reducer;

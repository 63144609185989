import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchHandler } from "../fetchHandler";

export const fetchPhotos = createAsyncThunk("fetchPhotos", async () => {
    const response = await fetchHandler({
        url: `/photos/get-photos`,
        method: "GET"
    });
    return response?.data;
});

export const fetchPhotoDetails = createAsyncThunk("fetchPhotoDetails", async (viewid) => {
    const response = await fetchHandler({
        url: `/photos/details/${viewid}`,
        method: "GET",
    });
    return response?.data;
});

export const uploadPhotos = createAsyncThunk("uploadPhotos", async (body) => {
    const response = await fetchHandler({
        url: `/photos/add-edit`,
        method: "POST",
        body
    });
    return response?.data;
});

export const photosSlice = createSlice({
    name: "photos",
    initialState: {
        fetched: false,
        loading: true,
        photos: [],
    },
    extraReducers: {
        [fetchPhotos.pending]: (state) => {
            state.photos = [];
            state.loading = true;
            state.fetched = true;
        },
        [fetchPhotos.fulfilled]: (state, action) => {
            state.photos = action.payload?.result ? action.payload?.result : [];
            state.loading = false;
        },
    },
});

export default photosSlice.reducer;
